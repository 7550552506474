import React, { useState, useMemo, useEffect } from "react"
import ReactDOM from "react-dom"
import { observer } from "mobx-react-lite"
import { usePopper } from "react-popper"
import styled from "styled-components"
import { em, rem } from "polished"

import { useStore } from "../store"

const SiteTooltip = () => {
  const store = useStore()

  const title = useMemo(() => {
    if (store.mapPointHovered) {
      const feature = store.geojson.features.find(
        (f) => f.id == store.mapPointHovered
      )

      if (feature) return feature.properties.name
    }

    return null
  }, [store.mapPointHovered])

  return title ? <Tooltip text={title} /> : null
}

export default observer(SiteTooltip)

const Tooltip = ({ text }) => {
  const [containerEl, setContainerEl] = useState(null)
  const [mousePos, setMousePos] = useState([0, 0])

  const referenceEl = useMemo(
    () => ({
      getBoundingClientRect: () => {
        return {
          top: mousePos[1] || 0,
          left: mousePos[0] || 0,
          bottom: mousePos[0],
          right: mousePos[1],
          width: 0,
          height: 0,
        }
      },
    }),
    [mousePos]
  )

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceEl,
    containerEl,
    {
      placement: `top-start`,
      modifiers: [
        {
          name: `flip`,
          enabled: false,
        },
        {
          name: `offset`,
          options: {
            offset: [6, 6],
          },
        },
      ],
    }
  )

  const docMouseMove = (e) => {
    setMousePos([e.clientX, e.clientY])
  }

  useEffect(() => {
    document.addEventListener(`mousemove`, docMouseMove)

    return () => {
      document.removeEventListener(`mousemove`, docMouseMove)
    }
  }, [])

  return ReactDOM.createPortal(
    <Container
      ref={setContainerEl}
      style={popperStyles.popper}
      {...popperAttributes.popper}
    >
      {text}
    </Container>,
    document.querySelector(`body`)
  )
}

const Container = styled.div`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

  z-index: ${({ theme }) => theme.zindex.siteTooltip};
  max-width: ${em(320)};
  padding: ${em(8)} ${em(14)};
  font-size: ${em(14)};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.greyDarkest};
  border-radius: ${rem(6)};
`
