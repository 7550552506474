import React from "react"
import { observer } from "mobx-react-lite"
import styled, { css } from "styled-components"
import { rem, rgba } from "polished"

import Translate from "./Translate"
import { ReactComponent as SvgPrint } from "../assets/images/icons/print.svg"
import getEmbedParams from "../utils/getEmbedParams"
import { useStore } from "../store"

const PrintToggle = () => {
  const store = useStore()

  const buttonClick = (e) => {
    e.preventDefault()
    store.setPrintActive(!store.printActive)
  }

  return (
    <Container
      active={store.printActive}
      stolavsleden={getEmbedParams(`stolavsleden`)}
    >
      <Translate text="controls.print">
        {(translation) => (
          <button type="button" title={translation} onClick={buttonClick}>
            <SvgPrint aria-label={translation} />
          </button>
        )}
      </Translate>
    </Container>
  )
}

export default observer(PrintToggle)

const Container = styled.div`
  button {
    width: ${rem(32)};
    height: ${rem(32)};
    padding: ${rem(8)};
    display: block;
    position: relative;
    border-radius: ${rem(6)};
    color: ${(props) => props.theme.colors.greyDarkest};
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0 ${rem(1)} ${rem(3)} ${rem(1)}
      ${(props) => rgba(props.theme.colors.black, 0.3)};

    &:hover {
      color: ${(props) => props.theme.colors.black};
    }

    &::before {
      /* expanded click area */
      content: "";
      position: absolute;
      top: ${rem(-5)};
      bottom: ${rem(-5)};
      left: ${rem(-10)};
      right: ${rem(-10)};
    }

    ${(props) =>
      props.stolavsleden &&
      css`
        color: ${(props) => props.theme.colors.white};
        background-color: ${(props) => props.theme.colors.stolavsledenRed};

        &:hover {
          color: ${(props) => props.theme.colors.white};
          background-color: ${(props) =>
            props.theme.colors.stolavsledenRedDark};
        }
      `}
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }

  ${(props) =>
    props.active &&
    css`
      button {
        color: ${(props) => props.theme.colors.white};
        background-color: ${(props) => props.theme.colors.black};

        &:hover {
          color: ${(props) => props.theme.colors.grey};
        }
      }
    `}
`
