import React from "react"
import { observer } from "mobx-react-lite"
import styled, { keyframes } from "styled-components"
import { rem, rgba } from "polished"

import Translate from "./Translate"
import { ReactComponent as SvgClose } from "../assets/images/icons/close.svg"
import { useStore } from "../store"

const MeasureInfo = () => {
  const store = useStore()

  const closeClick = () => store.setMeasureActive(false)

  return (
    <Container>
      {store.measureValue ? (
        <>
          <Translate text="measure_info.total" />:{` `}
          <strong>{store.measureValue} km</strong>
        </>
      ) : (
        <Translate text="measure_info.click_on_map" />
      )}

      <Translate text="_.close">
        {(translation) => (
          <Close onClick={closeClick} title={translation}>
            <SvgClose aria-label={translation} />
          </Close>
        )}
      </Translate>
    </Container>
  )
}

export default observer(MeasureInfo)

const slideInAnim = keyframes`
  0% { opacity: 0; transform: translateX(-100%); }
  100% { opacity: 1; transform: translateX(0); }
`

const nudgeAnim = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`

const Container = styled.div`
  padding: 0.8em 1.2em;
  padding-right: 2.4em;
  font-size: ${rem(14)};
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => rgba(props.theme.colors.black, 0.9)};
  border-radius: ${rem(3)};
  transform-origin: bottom left;
  animation: ${slideInAnim} 0.3s ${(props) => props.theme.easings.default},
    ${nudgeAnim} 0.4s 0.4s 3 ${(props) => props.theme.easings.default};

  @media ${(props) => props.theme.mq.largeDown} {
    font-size: ${rem(12)};
  }

  @media only print {
    color: ${(props) => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.white};
  }
`

const Close = styled.button.attrs({ type: `button` })`
  width: ${rem(25)};
  height: ${rem(25)};
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(40%, -40%);
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.black};
  border: 2px solid ${(props) => rgba(props.theme.colors.white, 0.6)};

  @media ${(props) => props.theme.mq.xsmallDown} {
    transform: translate(${rem(-8)}, -40%);
  }

  @media only print {
    visibility: hidden;
  }

  &:hover {
    border-color: ${(props) => props.theme.colors.white};
  }

  &::before {
    /* expanded click area */
    content: "";
    position: absolute;
    top: ${rem(-5)};
    bottom: ${rem(-5)};
    left: ${rem(-5)};
    right: ${rem(-5)};
  }

  svg {
    width: 100%;
    height: 100%;
    padding: ${rem(6)};
    display: block;
  }
`
