import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { rem, rgba } from "polished"

import Translate from "./Translate"
import { ReactComponent as SvgPlus } from "../assets/images/icons/plus.svg"
import { ReactComponent as SvgDash } from "../assets/images/icons/dash.svg"
import { useStore } from "../store"

const ZoomControl = () => {
  const store = useStore()

  const buttonClick = (e) => {
    e.preventDefault()
    store.toggleZoom(e.currentTarget.dataset.mode)
  }

  return (
    <Container>
      <Translate text="controls.zoom_in">
        {(translation) => (
          <button
            type="button"
            title={translation}
            onClick={buttonClick}
            data-mode="in"
          >
            <SvgPlus aria-label={translation} />
          </button>
        )}
      </Translate>

      <Translate text="controls.zoom_out">
        {(translation) => (
          <button
            type="button"
            title={translation}
            onClick={buttonClick}
            data-mode="out"
          >
            <SvgDash aria-label={translation} />
          </button>
        )}
      </Translate>
    </Container>
  )
}

export default observer(ZoomControl)

const btnBR = rem(5)

const Container = styled.div`
  border-radius: ${btnBR};
  box-shadow: 0 ${rem(1)} ${rem(3)} ${rem(1)}
    ${(props) => rgba(props.theme.colors.black, 0.3)};

  button {
    width: ${rem(32)};
    height: ${rem(32)};
    padding: ${rem(9)};
    display: block;
    position: relative;
    color: ${(props) => props.theme.colors.greyDarkest};
    background-color: ${(props) => props.theme.colors.white};

    &:hover,
    &:focus {
      z-index: 1;
      color: ${(props) => props.theme.colors.black};
    }

    &:active {
      background-color: ${(props) => props.theme.colors.grey};
    }

    &::before {
      /* expanded click area */
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: ${rem(-10)};
      right: ${rem(-10)};
    }

    &:first-child {
      border-radius: ${btnBR} ${btnBR} 0 0;

      &::before {
        top: ${rem(-5)};
      }
    }

    &:last-child {
      border-radius: 0 0 ${btnBR} ${btnBR};

      &::before {
        bottom: ${rem(-5)};
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${(props) => props.theme.colors.greyDark};
    }
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`
