import { orderBy } from "lodash"
import {
  featureCollection as turfFeatureCollection,
  lineString as turfLineString,
} from "@turf/helpers"
import queryString from "query-string"

import { stfTripIncludeCategories } from "../config/clientStf"
import getEmbedParams from "./getEmbedParams"
import generateId from "./generateId"

// integerifyGeojsonId (reason for this: https://github.com/mapbox/mapbox-gl-js/issues/2716)

const integerifyGeojsonId = (id) => {
  return parseInt([...String(id)].map((x) => x.charCodeAt()).join(``))
}

// fixGeojson

const fixGeojson = (json, params = {}) => {
  const { query, menuSort, stf } = getEmbedParams()

  // STF: remove items that are not Trip items and not in whitelisted categories
  if (stf && stf.trips) {
    const queryParsed = queryString.parse(query)
    const tripId = parseInt(queryParsed[`search[trip_id]`])
    const siteIds = queryParsed[`search[site_ids][]`] || []
    const childrenToRemove = []

    json.features = json.features.filter((f) => {
      if (
        f.geometry.type == `Point` &&
        f.properties.main_category &&
        !siteIds.includes(f.id) &&
        !f.properties.trip_ids.includes(tripId) &&
        !f.properties.categories.find((c) =>
          stfTripIncludeCategories.includes(c.slug)
        )
      ) {
        childrenToRemove.push(...f.properties.children)
        return false
      }
      return true
    })

    if (childrenToRemove.length) {
      json.features = json.features.filter(
        (f) => !childrenToRemove.includes(f.id)
      )
    }
  }

  // modify features props

  const trailSurfaces = []
  const wheelchairTestedId = generateId()

  json.features = json.features.map((item, i) => {
    if (item.geometry.type == `Point`) {
      if (!item.properties.main_category) {
        // @TODO: remove related `children`
        return null
      }

      // @TODO: use `main_category.slug/label` instead of `type_key/type_label` everywhere in the code
      item.properties.type_key = item.properties.main_category.slug || `none`
      item.properties.type_label = item.properties.main_category.label

      // icon name field

      if (item.properties.main_category.name) {
        item.properties.icon_name = item.properties.main_category.name
        item.properties.map_icon_name = `${item.properties.main_category.name}-${item.properties.type}`
        item.properties.map_icon_active_name = `${item.properties.main_category.name}-active`

        if (item.properties.type == `place`) {
          switch (item.properties.main_category.slug) {
            case `hub-301`: {
              item.properties.map_icon_name += `-favorites`
              break
            }

            case `hub-299`:
            case `accessible`: {
              item.properties.map_icon_name += `-nature-reserve`
              break
            }

            case `sledding`: {
              item.properties.map_icon_name += `-activity`
              break
            }

            case `bigcrowd`: {
              item.properties.map_icon_name += `-warning`
              break
            }

            case `crowd`: {
              item.properties.map_icon_name += `-attention`
              break
            }
          }
        }
      } else {
        item.properties.icon_name = `none`
        item.properties.map_icon_name = `none`
        item.properties.map_icon_active_name = `none-active`
      }

      // fix empty source field

      item.properties.source = item.properties.source || null
    }

    // remove empty coordinate groups (causes some Mapbox bugs)

    if (Array.isArray(item.geometry.coordinates[0][0])) {
      item.geometry.coordinates = item.geometry.coordinates.filter(
        (i) => i.length
      )
    }

    // modify id's

    item.properties.original_id = item.id
    item.id = integerifyGeojsonId(item.id)

    if (item.properties.parent) {
      item.properties.parent = integerifyGeojsonId(item.properties.parent)
    }

    if (item.properties.children) {
      item.properties.children = item.properties.children.map((i) =>
        integerifyGeojsonId(i)
      )
    }

    // surface trails

    if (item.properties.surfaces) {
      item.properties.surfaces = JSON.parse(item.properties.surfaces)

      if (item.properties.surfaces.length && item.properties.children.length) {
        const trailFeat = json.features.find((f) =>
          [f.id, integerifyGeojsonId(f.id)].includes(
            item.properties.children[0]
          )
        )

        if (trailFeat) {
          item.properties.surfaces.forEach(({ from, to, type }, i) => {
            const surfaceCoordinates = trailFeat.geometry.coordinates.slice(
              from > to ? to : from,
              (from > to ? from : to) + 1
            )
            if (!surfaceCoordinates.length) return

            const id = parseInt(`${item.id}${i}`)
            trailSurfaces.push(
              turfLineString(
                surfaceCoordinates,
                { surface: `yes`, type, parent: item.id },
                { id }
              )
            )
            item.properties.children.push(id)
          })
        }
      }
    }

    // add `wheelchair_tested` as a category

    if (item.properties.wheelchair_tested) {
      item.properties.categories.push({
        id: wheelchairTestedId,
        slug: `wheelchair-tested`,
        type: `AccessibilityType`,
        color: `#000`,
        label: `wheelchair-tested`,
      })
    }

    // trail status

    if (item.properties.trail_status_reported_at) {
      const at = item.properties.trail_status_reported_at.replace(
        /[+-]\d\d:\d\d/,
        ``
      )
      item.properties.trail_status_reported_at = `${at}+01:00`
    }

    return item
  })

  json.features = json.features.filter(Boolean)
  json.features.push(...trailSurfaces)

  // order features

  switch (menuSort) {
    case `abc`: // alphabetical
      json.features = orderBy(json.features, (f) => f.properties.name, [`asc`])
      break

    case `ns`: // north -> south
    default:
      json.features = orderBy(
        json.features,
        (f) =>
          typeof f.geometry.coordinates[1] === `number`
            ? f.geometry.coordinates[1]
            : 0,
        [`desc`]
      )
      break
  }

  return json
}

// filterGeojsonBy (categories and/or tags)

const filterGeojsonBy = (geojson, { categories = [], tags = [] }) => {
  if (!categories.length && !tags.length) {
    return geojson
  }

  const features = []

  geojson.features.forEach((feat) => {
    if (
      feat.geometry.type != `Point` ||
      !feat.properties.type_key ||
      !feat.properties.categories
    )
      return

    // let include = allBut
    // if (categories.includes(feat.properties.type_key)) include = !allBut
    // else {
    //   feat.properties.categories.forEach((cat) => {
    //     if (categories.includes(cat.slug)) include = !allBut
    //   })
    // }
    let include = false

    if (categories.length) {
      feat.properties.categories.some((category) => {
        if (categories.includes(category.slug)) {
          include = true
          return true
        }
        return false
      })
    }

    if (!include && tags.length) {
      feat.properties.tags.some((tag) => {
        if (tags.find((t) => t == tag.id)) {
          include = true
          return true
        }
        return false
      })
    }

    if (include) {
      features.push(feat)

      if (feat.properties.children.length) {
        feat.properties.children.forEach((id) =>
          features.push(geojson.features.find((f) => f.id === id))
        )
      }
    }
  })

  return {
    type: `FeatureCollection`,
    features,
  }
}

// splitGeojson (in two pieces: points & the rest)

const splitGeojson = (geojson) => {
  const points = []
  const rest = []

  if (geojson) {
    geojson.features.forEach((f) =>
      (f.geometry.type == `Point` ? points : rest).push(f)
    )
  }

  return [turfFeatureCollection(rest), turfFeatureCollection(points)]
}

// anyTrailSurfaceInGeojson

const anyTrailSurfaceInGeojson = (geojson) => {
  return !!geojson.features.find(
    (f) => f.properties.surfaces && f.properties.surfaces.length > 0
  )
}

// fetchApiGeojson

const fetchApiGeojson = ({ query, lang, friendly = false }) => {
  // let credentials = `include`

  // if (
  //   process.env.NODE_ENV !== `production` &&
  //   !process.env.REACT_APP_API_LOCAL_BASE
  // ) {
  //   credentials = `same-origin`
  // }

  let apiBase = `https://api.naturkartan.se`
  if (process.env.REACT_APP_API_LOCAL_BASE) {
    apiBase = process.env.REACT_APP_API_LOCAL_BASE
  } else {
    const customApiBase = getEmbedParams(`apiBase`)
    if (customApiBase) {
      apiBase = customApiBase
    }
  }

  const url = `${apiBase.replace(/\/+$/, ``)}/v2/sites/search.geojson`
  const urlQuery = [query, getEmbedParams(`edit`) && `bypass_published=true`]
    .filter(Boolean)
    .join(`&`)

  return window
    .fetch(`${url}?${urlQuery}`, {
      type: `GET`,
      // credentials: credentials,
      redirect: `follow`,
      cache: `no-cache`,
      headers: {
        "X-Language": lang,
        "Content-Type": `application/json`,
        Accept: `application/json */*`,
      },
    })
    .then((response) => {
      if (!response.ok) throw Error(response.statusText)
      return response
    })
    .then((response) => response.json())
    .then((geojson) => {
      if (geojson && geojson.features && geojson.features.length) {
        geojson = fixGeojson(geojson)
        if (geojson.features.length) {
          return geojson
        }
      }

      if (friendly) {
        return {
          type: `FeatureCollection`,
          features: [],
        }
      }

      throw Error(`FeatureCollection is empty`)
    })
    .catch((error) => {
      throw Error(error)
    })
}

export {
  integerifyGeojsonId,
  fetchApiGeojson,
  fixGeojson,
  filterGeojsonBy,
  splitGeojson,
  anyTrailSurfaceInGeojson,
}
