import React from "react"
import styled, { keyframes } from "styled-components"
import { rem } from "polished"
import marked from "marked"

import Translate from "./Translate"

const ErrorState = () => (
  <Container>
    <Inner>
      <h1>
        <Translate text="state.error_title" />
      </h1>

      <Translate text="state.error_text">
        {(translation) => (
          <div dangerouslySetInnerHTML={{ __html: marked(translation) }} />
        )}
      </Translate>
    </Inner>
  </Container>
)

export default ErrorState

const erroredAnim = keyframes`
  0% { opacity: 0; transform: scale(0); }
  100% { opacity: 1; transform: translateY(1); }
`

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  padding: ${rem(20)};
  display: flex;
  justify-content: center;
  align-items: center;
`

const Inner = styled.div`
  animation: ${erroredAnim} 0.4s ${(props) => props.theme.easings.outBack};

  h1 {
    ${({ theme }) => theme.fonts.set(`secondary`, `bold`)}

    font-size: ${rem(20)};
    text-align: center;
  }

  p {
    max-width: ${rem(260)};
    margin: ${rem(20)} 0 0;
    font-size: ${rem(14)};
    opacity: 0.7;
  }
`
