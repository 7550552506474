const stfTripIncludeCategories = [
  `hostel`,
  `camping`,
  `firesite`,
  `entrance`,
  `shelter`,
  `toilet`,
  `resting-cabin`,
]

export { stfTripIncludeCategories }
