import React from "react"
import { Layer } from "react-mapbox-gl"

import colors from "../theme/colors"

const lineOpacity = {
  "line-opacity": [`case`, [`==`, [`feature-state`, `visible`], `yes`], 1, 0],
}

const layerConfig = {
  sourceId: `geojson`,
  type: `line`,
  layout: {
    "line-join": `round`,
    // "line-cap": `round`,
  },
  // filter: [
  //   `match`,
  //   [`geometry-type`],
  //   [`LineString`, `MultiLineString`],
  //   true,
  //   false,
  // ],
  filter: [`all`, [`==`, `$type`, `LineString`], [`!=`, `surface`, `yes`]],
}

const MapTrails = () => (
  <>
    {/* active: glow */}

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 14,
        "line-color": [
          `case`,
          [`boolean`, [`feature-state`, `active`], false],
          colors.orange,
          `rgba(0, 0, 0, 0)`,
        ],
        ...lineOpacity,
      }}
      id="trails-active-glow"
    />

    {/* hover: glow */}

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 8,
        "line-color": [
          `case`,
          [`boolean`, [`feature-state`, `hover`], false],
          `#000`,
          `#fff`,
        ],
        "line-color-transition": { duration: 0 },
        ...lineOpacity,
      }}
      id="trails-hover-glow"
    />

    {/* trail */}

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 5,
        "line-dasharray": [1, 0.2],
        "line-color": [
          `case`,
          [`!=`, [`get`, `color`], null],
          [`get`, `color`],
          colors.brown,
        ],
        ...lineOpacity,
      }}
      id="trails"
    />

    {/* surface: background */}

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 5,
        "line-color": `#fff`,
        ...lineOpacity,
      }}
      filter={[`all`, [`==`, `$type`, `LineString`], [`==`, `surface`, `yes`]]}
    />

    {/* surface: dashed */}

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 5,
        "line-dasharray": [1, 0.2],
        "line-color": [
          `case`,
          [`==`, [`get`, `type`], `gravel`],
          colors.surfaceGravel,
          [`==`, [`get`, `type`], `asphalt`],
          colors.surfaceAsphalt,
          // colors.brown,
          `rgba(0, 0, 0, 0)`,
        ],
        ...lineOpacity,
      }}
      filter={[
        `all`,
        [`==`, `$type`, `LineString`],
        [`==`, `surface`, `yes`],
        [`in`, `type`, `gravel`, `asphalt`],
      ]}
    />

    {/* surface: solid */}

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 5,
        "line-color": [
          `case`,
          [`==`, [`get`, `type`], `bike-gravel`],
          colors.surfaceGravel,
          [`==`, [`get`, `type`], `bike-asphalt`],
          colors.surfaceAsphalt,
          // colors.brown,
          `rgba(0, 0, 0, 0)`,
        ],
        ...lineOpacity,
      }}
      filter={[
        `all`,
        [`==`, `$type`, `LineString`],
        [`==`, `surface`, `yes`],
        [`in`, `type`, `bike-gravel`, `bike-asphalt`],
      ]}
    />
  </>
)

export default MapTrails
