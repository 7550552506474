import React from "react"
import { Layer } from "react-mapbox-gl"

import colors from "../theme/colors"

const lineOpacity = {
  "line-opacity": [`case`, [`==`, [`feature-state`, `visible`], `yes`], 1, 0],
}

const layerConfig = {
  sourceId: `geojson`,
  filter: [
    `match`,
    [`geometry-type`],
    [`Polygon`, `MultiPolygon`],
    true,
    false,
  ],
  type: `line`,
  paint: {
    "line-width": 3,
    "line-color": [
      `case`,
      [`!=`, [`get`, `color`], null],
      [`get`, `color`],
      colors.green,
    ],
    ...lineOpacity,
  },
  layout: {
    "line-join": `round`,
    "line-cap": `round`,
  },
}

const MapAreas = () => (
  <>
    <Layer
      {...layerConfig}
      paint={{
        "line-width": 14,
        "line-color": [
          `case`,
          [`boolean`, [`feature-state`, `active`], false],
          colors.orange,
          `rgba(0, 0, 0, 0)`,
        ],
        ...lineOpacity,
      }}
    />

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 8,
        "line-color": [
          `case`,
          [`boolean`, [`feature-state`, `hover`], false],
          `#000`,
          `#fff`,
        ],
        ...lineOpacity,
      }}
    />

    <Layer {...layerConfig} id="areas" />
  </>
)

export default MapAreas
