import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import styled, { css, keyframes } from "styled-components"
import { rem, rgba } from "polished"

import Translate from "./Translate"
import { ReactComponent as SvgEnter } from "../assets/images/icons/fullscreen-enter.svg"
import { ReactComponent as SvgExit } from "../assets/images/icons/fullscreen-exit.svg"
import { useStore } from "../store"

const FullscreenToggle = () => {
  const store = useStore()
  const [wasClicked, setWasClicked] = useState(false)

  const click = (e) => {
    e.preventDefault()

    store.toggleFullscreenActive()
    setWasClicked(true)
  }

  return (
    <Container active={store.fullscreenActive} wasClicked={wasClicked}>
      <Translate
        text={
          store.fullscreenActive
            ? `controls.fullscreen_exit`
            : `controls.fullscreen_enter`
        }
      >
        {(translation) => (
          <button type="button" title={translation} onClick={click}>
            {store.fullscreenActive ? (
              <SvgExit aria-label={translation} />
            ) : (
              <SvgEnter aria-label={translation} />
            )}
          </button>
        )}
      </Translate>
    </Container>
  )
}

export default observer(FullscreenToggle)

const underlayAnim = keyframes`
  50% { transform: scale(1.1); opacity: 0.6; }
`

const Container = styled.div`
  button {
    width: ${rem(32)};
    height: ${rem(32)};
    padding: ${rem(7)};
    display: block;
    position: relative;
    border-radius: ${rem(6)};
    color: ${(props) => props.theme.colors.greyDarkest};
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0 ${rem(1)} ${rem(3)} ${rem(1)}
      ${(props) => rgba(props.theme.colors.black, 0.3)};

    &:hover {
      color: ${(props) => props.theme.colors.black};
    }

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: ${rem(-3)};
      bottom: ${rem(-3)};
      left: ${rem(-3)};
      right: ${rem(-3)};
      border-radius: ${rem(8)};
      background-color: ${(props) => props.theme.colors.orange};
      animation: ${underlayAnim} 3s ${(props) => props.theme.easings.default}
        infinite;
    }

    &::after {
      /* expanded click area */
      content: "";
      position: absolute;
      z-index: -2;
      top: ${rem(-5)};
      bottom: ${rem(-5)};
      left: ${rem(-10)};
      right: ${rem(-10)};
    }
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }

  ${(props) =>
    props.active &&
    css`
      button {
        color: ${(props) => props.theme.colors.white};
        background-color: ${(props) => props.theme.colors.black};

        &:hover {
          color: ${(props) => props.theme.colors.grey};
        }

        &::before {
          display: none;
        }
      }
    `}

  ${(props) =>
    props.wasClicked &&
    css`
      button {
        &::before {
          display: none;
        }
      }
    `}
`
