import queryString from "query-string"
import { camelizeKeys } from "camelize-object-keys"

import { integerifyGeojsonId } from "./geojson"

const params = camelizeKeys(queryString.parse(window.location.search))

Object.entries(params).forEach(([key, value]) => {
  if (value === `true`) params[key] = true
  else if (value === `false`) params[key] = false
})

if (params.stf) {
  params.stf = JSON.parse(params.stf)
}

if (params.preselectedSiteId) {
  params.preselectedSiteId = integerifyGeojsonId(params.preselectedSiteId)
}

const getEmbedParams = (paramName = null) => {
  return paramName ? params[paramName] : params
}

export default getEmbedParams
