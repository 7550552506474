import { lighten, darken } from "polished"

const grey = `#eaeae4`
const beige = `#fffcf3`
const green = `#319852`
const red = `#e02500`

export default {
  white: `#fff`,
  black: `#222`,

  grey: grey,
  greyLight: lighten(0.05, grey),
  greyLighter: lighten(0.06, grey),
  greyDark: darken(0.1, grey),
  greyDarker: darken(0.45, grey),
  greyDarkest: darken(0.65, grey),

  beige: beige,
  beigeDark: darken(0.05, beige),
  beigeDarker: darken(0.1, beige),
  beigeDarkest: darken(0.2, beige),

  green: green,
  greenLight: `#0dbc9a`,
  greenDark: darken(0.1, green),

  red: red,
  redLight: `#f76757`,

  blue: `#3a8aa6`,
  blueDark: `#1f4675`,

  brown: `#7f522c`,

  purple: `#7c458f`,

  pink: `#ff7bac`,

  orange: `#e47e2a`,

  yellow: `#ffd200`,

  // by function

  surfaceAsphalt: green,
  surfaceGravel: red,

  // client: stolavsleden

  stolavsledenRed: `#d23833`,
  stolavsledenRedDark: `#822123`,
}
