import { toJS } from "mobx"
import turfLength from "@turf/length"

const getTrailLengthByPoint = (point, geojson) => {
  let trailLength = false

  if (point.properties.type === `trail` && point.properties.children.length) {
    trailLength = point.properties.length

    if (!trailLength) {
      const trail = geojson.features.find(
        (f) => f.id === point.properties.children[0]
      )
      if (trail) {
        trailLength = turfLength(toJS(trail), {
          units: `kilometers`,
        })
      }
    }

    if (trailLength) {
      trailLength = parseFloat(trailLength).toFixed(1)
    }
  }

  return trailLength
}

export { getTrailLengthByPoint }
