import React from "react"
import { Marker } from "react-mapbox-gl"
import styled, { keyframes } from "styled-components"
import { rem } from "polished"

import { ReactComponent as SvgLocation } from "../assets/images/icons/location.svg"

const MapLocation = (props) => (
  <Marker
    coordinates={props.coordinates}
    // coordinates={[18.40244614899029, 68.1252874925225]}
  >
    <Container>
      <SvgLocation />
    </Container>
  </Marker>
)

export default MapLocation

const nudgeAnim = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.8); }
  100% { transform: scale(1); }
`

const Container = styled.div`
  width: ${rem(26)};
  height: ${rem(26)};
  padding: ${rem(5)};
  background-color: ${(props) => props.theme.colors.red};
  border-radius: 50%;
  animation: ${nudgeAnim} 0.5s 3 ${(props) => props.theme.easings.default};

  svg {
    width: 100%;
    height: 100%;
    display: block;
    color: ${(props) => props.theme.colors.white};
  }
`
