const files = require.context(`./`, false, /\.yml/)
let translations = {}

files.keys().forEach((filename) => {
  translations = { ...files(filename), ...translations }
})

const languages = Object.keys(translations)
const defaultLanguage = languages.includes(`en`) ? `en` : languages[0]

export { translations as default, languages, defaultLanguage }
