import React, { Suspense, lazy, useEffect, useRef } from "react"
import { reaction } from "mobx"
import { observer } from "mobx-react-lite"
import styled, {
  css,
  keyframes,
  createGlobalStyle,
  ThemeProvider,
} from "styled-components"
import { rem, em, rgba } from "polished"
import "@oddcamp/sass-utils/src/reset.scss"

import Map from "./components/Map"
import SiteTooltip from "./components/SiteTooltip"
import ZoomControl from "./components/ZoomControl"
import StyleControl from "./components/StyleControl"
import MenuToggle from "./components/MenuToggle"
import FiltersToggle from "./components/FiltersToggle"
import FullscreenToggle from "./components/FullscreenToggle"
import MeasureToggle from "./components/MeasureToggle"
import LocationToggle from "./components/LocationToggle"
import PrintToggle from "./components/PrintToggle"
import LegendToggle from "./components/LegendToggle"
import ShareToggle from "./components/ShareToggle"
import ElevationsToggle from "./components/ElevationsToggle"
import EditToggle from "./components/EditToggle"
import Menu from "./components/Menu"
import Filters from "./components/Filters"
import Legend from "./components/Legend"
import PrintZone from "./components/PrintZone"
import MeasureInfo from "./components/MeasureInfo"
import ErrorState from "./components/ErrorState"

import { useStore } from "./store"
import getEmbedParams from "./utils/getEmbedParams"
import centerScrollInParent from "./utils/centerScrollInParent"
import postEmbedMessage from "./utils/postEmbedMessage"
import parsePostMessage from "./utils/parsePostMessage"
import useReferredState from "./utils/useReferredState"
import {
  fetchApiGeojson,
  fixGeojson,
  integerifyGeojsonId,
} from "./utils/geojson"
import theme from "./theme"

const Elevations = lazy(() => import(`./components/Elevations`))
const ShareDialog = lazy(() => import(`./components/ShareDialog`))
const PointCard = lazy(() => import(`./components/PointCard`))

const embedParams = getEmbedParams()

let geojsonWasFetched = false
let fullscreenTO = null
let initialDataWasSentBack = false
let sidebarActive = false

const App = () => {
  const store = useStore()
  const sidebarEl = useRef()
  const menuToggleEl = useRef()
  const filtersToggleEl = useRef()
  const styleControlWrapEl = useRef()
  const [status, statusRef, setStatus] = useReferredState(`loading`) // loading|success|error

  const fetchData = () => {
    if (!embedParams.query) {
      geojsonWasFetched = true
      return
    }

    setStatus(`loading`)

    fetchApiGeojson({ query: embedParams.query, lang: store.language })
      .then((geojson) => {
        store.setGeojson(geojson)
        geojsonWasFetched = true
        if (initialDataWasSentBack) setStatus(`success`)

        if (
          embedParams.legend &&
          embedParams.legendControl &&
          store.anyTrailSurfaceInGeojson
        )
          store.setLegendActive(true)
      })
      .catch((error) => {
        setStatus(`error`)
        // eslint-disable-next-line no-console
        console.error(`Fetch error: ${String(error)}`)
      })
  }

  const winMessage = (e) => {
    const { method, data } = parsePostMessage(e)

    switch (method) {
      case `initialData`: {
        const { siteLinks, editData, geojson } = data

        if (siteLinks) store.setCustomSiteLinks(siteLinks)
        if (editData) store.setEditData(editData)
        if (geojson) store.setGeojson(fixGeojson(geojson))

        initialDataWasSentBack = true
        if (statusRef.current === `loading` && geojsonWasFetched)
          setStatus(`success`)
        break
      }

      case `setQuery`: {
        fetchApiGeojson({
          query: data,
          lang: store.language,
          friendly: true,
        }).then((geojson) => store.setGeojson(geojson))
        break
      }

      case `toggleFullscreenActive`: {
        store.toggleFullscreenActive()
        break
      }

      case `setMapPointActive`: {
        store.setMapPointActive(data ? integerifyGeojsonId(data) : null)
        break
      }

      case `setMapPointHovered`: {
        store.setMapPointHovered(data ? integerifyGeojsonId(data) : null)
        break
      }

      case `setCategoriesActive`: {
        // @TODO: apply the same verification as in Map->setPreselectedCategories()
        store.setCategoriesActive(data, true, false)
        break
      }

      case `setTagsActive`: {
        store.setTagsActive(data, true, false)
        break
      }

      case `setEditPointsActive`: {
        store.setEditPointsActive(data)
        break
      }
    }
  }

  const winKeyup = (e) => {
    if (e.key === `Escape`) {
      if (store.measureActive) {
        store.setMeasureActive(false)
      } else if (store.mapPointActive) {
        store.setMapPointActive(null)
      } else if (store.shareActive) {
        store.setShareActive(false)
      } else if (store.fullscreenActive) {
        store.toggleFullscreenActive()
      }
    }
  }

  const styleControlBtnClick = (e) => {
    centerScrollInParent(styleControlWrapEl.current, e.currentTarget)
  }

  const scrollToActiveMenuSite = () => {
    if (!store.mapPointActive || !sidebarEl.current) return

    const target = sidebarEl.current.querySelector(
      `[data-menu-point-id="${store.mapPointActive}"]`
    )

    if (target) {
      centerScrollInParent(sidebarEl.current, target)
    }
  }

  const sidebarTransitionEnd = () => {
    window.dispatchEvent(new Event(`resize`)) // fixes Mapbox resize issue
    scrollToActiveMenuSite()
  }

  useEffect(() => {
    window.addEventListener(`keyup`, winKeyup, { passive: true })
    window.addEventListener(`message`, winMessage, { passive: true })

    fetchData()
    postEmbedMessage(`loaded`)

    const reactionMapPointActive = reaction(
      () => store.mapPointActive,
      () => {
        scrollToActiveMenuSite()
      }
    )

    const reactionFullscreenActive = reaction(
      () => store.fullscreenActive,
      () => {
        if (store.printActive) return

        if (embedParams.menuActive === `fullscreen`) {
          window.clearTimeout(fullscreenTO)
          fullscreenTO = window.setTimeout(() => {
            store.toggleSidebarActive(
              window.matchMedia(theme.mq.xlargeUp).matches ? `menu` : false
            )
          }, 500)
        } else if (embedParams.filtersActive === `fullscreen`) {
          window.clearTimeout(fullscreenTO)
          fullscreenTO = window.setTimeout(() => {
            store.toggleSidebarActive(
              window.matchMedia(theme.mq.xlargeUp).matches ? `filters` : false
            )
          }, 500)
        }
      }
    )

    const reactionSidebarActive = reaction(
      () => store.sidebarActive,
      () => {
        if (!store.sidebarActive && sidebarActive) {
          switch (sidebarActive) {
            case `menu`: {
              if (menuToggleEl.current) menuToggleEl.current.focus()
              break
            }

            case `filters`: {
              if (filtersToggleEl.current) filtersToggleEl.current.focus()
              break
            }
          }
        }

        sidebarActive = store.sidebarActive
      }
    )

    return () => {
      window.removeEventListener(`keyup`, winKeyup, { passive: true })
      window.removeEventListener(`message`, winMessage, { passive: true })
      window.clearTimeout(fullscreenTO)

      reactionMapPointActive()
      reactionFullscreenActive()
      reactionSidebarActive()
    }
  }, [])

  let menuControl = true
  if (embedParams.menu !== undefined) {
    menuControl =
      embedParams.menu === `fullscreen`
        ? store.fullscreenActive
        : embedParams.menu
  }

  let filtersControl = true
  if (embedParams.filters !== undefined) {
    filtersControl =
      embedParams.filters === `fullscreen`
        ? store.fullscreenActive
        : embedParams.filters
  }

  let styleControl = true
  if (embedParams.styleControl !== undefined) {
    styleControl =
      embedParams.styleControl === `fullscreen`
        ? store.fullscreenActive
        : embedParams.styleControl
  }

  let measureControl = true
  if (embedParams.measureControl !== undefined) {
    measureControl =
      embedParams.measureControl === `fullscreen`
        ? store.fullscreenActive
        : embedParams.measureControl
  }

  let locationControl = `geolocation` in navigator
  if (embedParams.locationControl !== undefined) {
    locationControl =
      embedParams.locationControl === `fullscreen`
        ? store.fullscreenActive
        : embedParams.locationControl
  }

  let printControl = true
  if (embedParams.printControl !== undefined) {
    printControl =
      embedParams.printControl === `fullscreen`
        ? store.fullscreenActive
        : embedParams.printControl
  }

  let shareControl = false
  if (embedParams.shareControl !== undefined) {
    shareControl =
      embedParams.shareControl === `fullscreen`
        ? store.fullscreenActive
        : embedParams.shareControl
  }

  let zoomControl = true
  if (embedParams.zoomControl !== undefined) {
    zoomControl =
      embedParams.zoomControl === `fullscreen`
        ? store.fullscreenActive
        : embedParams.zoomControl
  }

  let legendControl = false
  if (store.anyTrailSurfaceInGeojson) {
    legendControl =
      embedParams.legendControl === `fullscreen`
        ? store.fullscreenActive
        : embedParams.legendControl
  }

  let fullscreenControl = true
  if (embedParams.fullscreenControl !== undefined)
    fullscreenControl = embedParams.fullscreenControl

  return (
    <ThemeProvider theme={theme}>
      <>
        <InitialStyles />

        {status === `success` && (
          <Container>
            <Suspense fallback={null}>
              {store.shareActive && <ShareDialog />}
            </Suspense>

            <Sidebar
              active={store.sidebarActive}
              onTransitionEnd={sidebarTransitionEnd} // {() => window.dispatchEvent(new Event(`resize`))} // fixes Mapbox resize issue
              ref={sidebarEl}
            >
              {store.sidebarActive === `menu` && (
                <div>
                  <Menu />
                </div>
              )}

              {store.sidebarActive === `filters` && (
                <div>
                  <Filters />
                </div>
              )}
            </Sidebar>

            <MapZone
              sidebarActive={store.sidebarActive}
              printActive={store.printActive}
            >
              {(menuControl || filtersControl) && (
                <TopLeft>
                  {menuControl && (
                    <MenuToggle
                      active={store.sidebarActive === `menu`}
                      buttonRef={menuToggleEl}
                    />
                  )}

                  {filtersControl && (
                    <FiltersToggle
                      active={store.sidebarActive === `filters`}
                      buttonRef={filtersToggleEl}
                    />
                  )}
                </TopLeft>
              )}

              {styleControl && (
                <StyleControlWrap ref={styleControlWrapEl}>
                  <StyleControl onButtonClick={styleControlBtnClick} />
                </StyleControlWrap>
              )}

              <TopRight>
                {measureControl && <MeasureToggle />}

                {locationControl && <LocationToggle />}

                {printControl && <PrintToggle />}

                {shareControl && <ShareToggle />}

                {legendControl && <LegendToggle />}

                {store.elevationsFeat && (
                  <ElevationsToggleWrap>
                    <ElevationsToggle />
                  </ElevationsToggleWrap>
                )}
              </TopRight>

              <BottomRight>
                {zoomControl && <ZoomControl />}

                {fullscreenControl && (
                  <FullscreenToggleWrap>
                    <FullscreenToggle />
                  </FullscreenToggleWrap>
                )}
              </BottomRight>

              <BottomLeft>
                {embedParams.edit && <EditToggle />}

                {store.measureActive && <MeasureInfo />}
              </BottomLeft>

              {store.legendActive && (
                <LegendWrap>
                  <Legend />
                </LegendWrap>
              )}

              <Suspense fallback={null}>
                {store.elevationsDisplay && store.elevationsFeat && (
                  <ElevationsWrap>
                    <Elevations />
                  </ElevationsWrap>
                )}
              </Suspense>

              <Suspense fallback={null}>
                {store.pointCardData && (
                  <PointCardWrap>
                    <PointCard />
                  </PointCardWrap>
                )}
              </Suspense>

              {store.printActive && <PrintZone />}

              <MapWrap>
                <Map />
              </MapWrap>

              <Attribution>
                <a
                  href="https://www.mapbox.com/about/maps"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  © Mapbox
                </a>
                {` `}
                <a
                  href="http://www.openstreetmap.org/about"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  © OpenStreetMap
                </a>
                {` `}
                <a
                  href="http://www.thunderforest.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  © Thunderforest
                </a>
              </Attribution>
            </MapZone>

            {embedParams.edit && <SiteTooltip />}
          </Container>
        )}

        {status === `error` && (
          <>
            <HideLoadingIndicator />
            <ErrorState />
          </>
        )}
      </>
    </ThemeProvider>
  )
}

export default observer(App)

const InitialStyles = createGlobalStyle`
  * {
    line-height: calc(2px + 2.3ex + 2px); /* https://hugogiraudel.com/2020/05/18/using-calc-to-figure-out-optimal-line-height/ */
  }

  html,
  body {
    width: 100%;
    overflow-x: hidden;
  }

  html {
    ${({ theme }) => theme.fonts.set(`primary`, `normal`)}

    min-height: 100%;
    font-size: 100%;  /* a11y */
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.greyLight};
    background-image: url("/images/logo-animated.svg");
    background-size: 3.75rem 3.75rem;
    background-position: center center;
    background-repeat: no-repeat;
  }

  strong {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}
  }

  @media print {
    @page {
      size: A4 landscape;
    }
  }
`

const HideLoadingIndicator = createGlobalStyle`
  html {
    background-image: none;
  }
`

const sidebarInnerAnim = keyframes`
  0% { opacity: 0; transform: translateY(${rem(-10)}); }
  100% { opacity: 1; transform: translateY(0); }
`

const elevationsToggleAnim = keyframes`
  0% { opacity: 0; transform: translateX(100%); }
  100% { opacity: 1; transform: translateX(0); }
`

const elevationsToggleNudgeAnim = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.4); }
  100% { transform: scale(1); }
`

const TopLeft = styled.div`
  position: absolute;
  z-index: 5;
  top: ${rem(10)};
  left: ${rem(10)};
  pointer-events: none;

  > * {
    pointer-events: auto;

    + * {
      margin-top: ${rem(10)};
    }
  }
`

const StyleControlWrap = styled.div`
  ${({ theme }) => theme.mixins.hideScrollbar()}

  max-width: calc(100% - ${rem(50)});
  position: absolute;
  z-index: 2;
  top: ${rem(5)};
  right: 0;
  padding: ${rem(5)} ${rem(10)};
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  text-align: right;
  font-size: ${rem(13)};
  mask: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) ${rem(10)},
    rgba(0, 0, 0, 1) calc(100% - ${rem(10)}),
    rgba(0, 0, 0, 0) 100%
  );

  > * {
    display: inline-block;
  }
`

const TopRight = styled.div`
  ${({ theme }) => theme.mixins.hideScrollbar()}

  max-height: calc(100% - ${rem(115)});
  padding: ${rem(10)} ${rem(10)};
  position: absolute;
  z-index: 4;
  top: 0;
  right: 0;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  mask: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) ${rem(10)},
    rgba(0, 0, 0, 1) calc(100% - ${rem(10)}),
    rgba(0, 0, 0, 0) 100%
  );

  > * + * {
    margin-top: ${rem(10)};
  }

  ${StyleControlWrap} + & {
    max-height: calc(100% - ${rem(150)});
    top: ${rem(35)};
  }
`

const BottomRight = styled.div`
  position: absolute;
  z-index: 4;
  bottom: ${rem(10)};
  right: ${rem(10)};

  > * + * {
    margin-top: ${rem(10)};
  }
`

const BottomLeft = styled.div`
  max-width: calc(100% - ${rem(65)});
  position: absolute;
  z-index: 5;
  bottom: ${rem(25)};
  left: ${rem(10)};
  pointer-events: none;

  > * {
    pointer-events: auto;

    + * {
      margin-top: ${rem(10)};
    }
  }
`

const LegendWrap = styled.div`
  max-width: calc(100% - ${rem(65)});
  position: absolute;
  z-index: 5;
  bottom: ${rem(10)};
  left: ${rem(10)};
`

const FullscreenToggleWrap = styled.div``

const ElevationsToggleWrap = styled.div`
  opacity: 0;
  transform-origin: center center;
  animation: ${elevationsToggleAnim} ${(props) => props.theme.easings.inOutBack}
      0.5s 0.5s forwards,
    ${elevationsToggleNudgeAnim} 0.5s 1.5s 3
      ${(props) => props.theme.easings.default};
`

const ElevationsWrap = styled.div`
  height: 25vh;
  max-height: ${rem(200)};
  min-height: ${rem(100)};
  position: absolute;
  z-index: 5;
  left: ${rem(10)};
  right: ${rem(60)};
  bottom: ${rem(10)};

  @media ${(props) => props.theme.mq.xsmallDown} {
    right: ${rem(50)};
  }
`

const PointCardWrap = styled.div`
  width: calc(100% - ${rem(60)});
  max-width: ${rem(420)};
  position: absolute;
  z-index: 4;
  left: ${rem(10)};
  bottom: ${rem(10)};

  .-pointcard-inner {
    max-height: ${rem(380)};

    @media (max-height: ${em(480)}) {
      max-height: calc(100vh - ${rem(30)});
    }
  }

  ${TopLeft} ~ & {
    .-pointcard-inner {
      @media (max-height: ${em(480)}) {
        max-height: calc(100vh - ${rem(110)});
      }
    }
  }

  ${ElevationsWrap} ~ & {
    bottom: calc(25vh + ${rem(10 + 10)});

    @media (min-height: ${em(800)}) {
      bottom: ${rem(200 + 10 + 10)};
    }

    .-pointcard-inner {
      @media (max-height: ${em(680)}) {
        max-height: calc(100vh - 25vh - ${rem(40)});
      }
    }
  }

  ${TopLeft} ~ ${ElevationsWrap} ~ & {
    .-pointcard-inner {
      @media (max-height: ${em(680)}) {
        max-height: calc(100vh - 25vh - ${rem(110 + 10 + 10)});
      }
    }
  }
`

const Attribution = styled.div`
  ${({ theme }) => theme.mixins.hideScrollbar()}

  position: absolute;
  z-index: 2;
  bottom: ${rem(3)};
  left: 0;
  right: ${rem(150)};
  padding: 0 ${rem(10)};
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  mask: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) ${rem(10)},
    rgba(0, 0, 0, 1) calc(100% - ${rem(10)}),
    rgba(0, 0, 0, 0) 100%
  );
  font-size: ${rem(10)};
  text-shadow: 0 0 ${rem(2)} rgba(0, 0, 0, 0.7);
  color: ${(props) => props.theme.colors.white};

  a {
    &:hover,
    &:focus {
      text-shadow: 0 0 ${rem(3)} rgba(0, 0, 0, 1);
    }
  }
`

const MapWrap = styled.div`
  width: 100%;
  height: 100%;
`

const MapZone = styled.div`
  height: 100%;
  order: 2;
  flex-grow: 1;
  position: relative;
  z-index: 1;
  overflow: hidden;

  ${(props) =>
    props.sidebarActive &&
    css`
      @media ${(props) => props.theme.mq.mediumDown} {
        ${MapWrap} {
          position: relative;

          &::after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            background-color: ${(props) => rgba(props.theme.colors.black, 0.6)};
          }
        }

        ${StyleControlWrap},
        ${TopRight},
        ${BottomLeft},
        ${LegendWrap},
        ${ElevationsWrap} {
          display: none;
        }
      }
    `}

  ${(props) =>
    props.printActive &&
    css`
      ${TopLeft},
      ${ElevationsWrap},
      ${ElevationsToggleWrap} {
        visibility: hidden;
      }

      ${FullscreenToggleWrap} {
        display: none;
      }

      ${BottomLeft} {
        top: ${rem(10)};
        bottom: auto;
      }

      ${LegendWrap} {
        top: ${rem(10)};
        bottom: auto;
      }

      ${PointCardWrap} {
        top: ${rem(10)};
        bottom: auto;
      }
    `}

  ${TopLeft},
  ${StyleControlWrap},
  ${TopRight},
  ${BottomRight},
  ${ElevationsWrap},
  ${ElevationsToggleWrap} {
    @media only print {
      visibility: hidden;
    }
  }
`

const Sidebar = styled.aside`
  min-width: 0;
  width: 0;
  height: 100%;
  order: 1;
  position: relative;
  z-index: 3;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: ${(props) => props.theme.colors.greyLight};
  box-shadow: ${rem(2)} 0 ${rem(4)} 0
    ${(props) => rgba(props.theme.colors.black, 0.2)};
  transition: 0.3s ${(props) => props.theme.easings.default};
  transition-property: width, min-width;

  @media ${(props) => props.theme.mq.mediumDown} {
    font-size: ${rem(15)};
  }

  &::-webkit-scrollbar {
    width: 0.4em;
    background-color: ${(props) => props.theme.colors.grey};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.greyDark};
  }

  > div {
    opacity: 0;
    animation: ${sidebarInnerAnim} 0.2s 0.3s
      ${(props) => props.theme.easings.default} forwards;
  }

  ${(props) =>
    props.active &&
    css`
      max-width: 100%;
      min-width: ${rem(320)};
      max-width: ${rem(480)};
      width: 30%;

      @media ${(props) => props.theme.mq.mediumDown} {
        width: calc(100% - ${rem(50)});
        min-width: auto;
        max-width: auto;
      }
    `}
`

const Container = styled.main`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  transition: padding-left 0.2s ${(props) => props.theme.easings.default};

  *:focus {
    outline: 2px solid ${(props) => rgba(props.theme.colors.green, 0.8)};
    outline-offset: 2px;
  }
`
