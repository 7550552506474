import React from "react"
import { observer } from "mobx-react-lite"
import styled, { keyframes } from "styled-components"
import { em, rem, rgba } from "polished"

import Translate from "./Translate"
import { ReactComponent as SvgClose } from "../assets/images/icons/close.svg"
import { useStore } from "../store"

const Legend = () => {
  const store = useStore()

  const closeClick = () => store.setLegendActive(false)

  return (
    <Container>
      <Translate
        text={[
          `legend.gravel`,
          `legend.asphalt`,
          `legend.bike_gravel`,
          `legend.bike_asphalt`,
        ]}
      >
        {(t) => (
          <Surfaces>
            <ul>
              <li className="--gravel">{t[0]}</li>

              <li className="--asphalt">{t[1]}</li>

              <li className="--bike-gravel">{t[2]}</li>

              <li className="--bike-asphalt">{t[3]}</li>
            </ul>
          </Surfaces>
        )}
      </Translate>

      <Translate text="_.close">
        {(t) => (
          <Close onClick={closeClick} title={t}>
            <SvgClose aria-label={t} />
          </Close>
        )}
      </Translate>
    </Container>
  )
}

export default observer(Legend)

const slideInAnim = keyframes`
  0% { opacity: 0; transform: translateX(-100%); }
  100% { opacity: 1; transform: translateX(0); }
`

const nudgeAnim = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`

const Container = styled.div`
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => rgba(props.theme.colors.black, 0.9)};
  border-radius: ${rem(3)};
  transform-origin: bottom left;
  animation: ${slideInAnim} 0.3s ${(props) => props.theme.easings.default},
    ${nudgeAnim} 0.4s 0.4s 3 ${(props) => props.theme.easings.default};

  @media ${(props) => props.theme.mq.largeDown} {
    font-size: ${rem(15)};
  }

  @media only print {
    color: ${(props) => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.white};
  }
`

const Surfaces = styled.div`
  ${({ theme }) => theme.mixins.hideScrollbar()}

  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  mask: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 0.6em,
    rgba(0, 0, 0, 1) calc(100% - 0.6em),
    rgba(0, 0, 0, 0) 100%
  );

  ul {
    padding: 0.8em 1.2em;
    padding-right: 1.4em;
    display: inline-flex;
    font-size: ${em(13)};
  }

  li {
    display: flex;
    white-space: nowrap;

    &:not(:last-child) {
      margin-right: 1em;
    }

    &::before {
      content: "";
      width: 2.5em;
      border-top: 0.4em solid;
      margin-top: 0.5em;
      margin-right: 0.4em;
    }

    &.\--gravel,
    &.\--bike-gravel {
      &::before {
        border-top-color: ${(props) => props.theme.colors.surfaceGravel};
      }
    }

    &.\--asphalt,
    &.\--bike-asphalt {
      &::before {
        border-top-color: ${(props) => props.theme.colors.surfaceAsphalt};
      }
    }

    &.\--gravel,
    &.\--asphalt {
      &::before {
        border-top-style: dashed;
      }
    }
  }
`

const Close = styled.button.attrs({ type: `button` })`
  width: ${rem(25)};
  height: ${rem(25)};
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(40%, -40%);
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.black};
  border: 2px solid ${(props) => rgba(props.theme.colors.white, 0.6)};

  @media ${(props) => props.theme.mq.xsmallDown} {
    transform: translate(${rem(-8)}, -40%);
  }

  @media only print {
    visibility: hidden;
  }

  &:hover {
    border-color: ${(props) => props.theme.colors.white};
  }

  &::before {
    /* expanded click area */
    content: "";
    position: absolute;
    top: ${rem(-5)};
    bottom: ${rem(-5)};
    left: ${rem(-5)};
    right: ${rem(-5)};
  }

  svg {
    width: 100%;
    height: 100%;
    padding: ${rem(6)};
    display: block;
  }
`
