import React, { useState, useEffect, useRef } from "react"
import { observer } from "mobx-react-lite"
import styled, { css } from "styled-components"
import { rem, rgba } from "polished"

import Translate from "./Translate"
import { useStore } from "../store"

const PrintZone = () => {
  const store = useStore()
  const [printed, setPrinted] = useState(false)
  const printBtnRef = useRef(null)

  let wasSidebarActive = null
  let wasFullscreenActive = null
  const isIOS = !!navigator.userAgent.match(/(iPad|iPhone|iPod)/g)
  const isFirefox = !!window.navigator.userAgent.match(/firefox/i)

  const cancelClick = (e) => {
    e.preventDefault()
    store.setPrintActive(false)
  }

  const printClick = (e) => {
    e.preventDefault()

    if (isFirefox) {
      window.addEventListener(`afterprint`, afterPrint, { passive: true })
    } else {
      window.matchMedia(`print`).addListener(afterPrint)
    }

    window.print()
  }

  const afterPrint = (e) => {
    if (isFirefox) {
      window.removeEventListener(`afterprint`, afterPrint, {
        passive: true,
      })
    } else {
      if (e.matches) return
      window.matchMedia(`print`).removeListener(afterPrint)
    }

    setPrinted(true)
  }

  useEffect(() => {
    wasSidebarActive = store.sidebarActive
    wasFullscreenActive = store.fullscreenActive

    if (store.sidebarActive) {
      store.toggleSidebarActive(false)
    }

    if (!store.fullscreenActive) {
      store.toggleFullscreenActive()
    }

    printBtnRef.current.focus()

    return () => {
      if (wasSidebarActive) {
        store.toggleSidebarActive(wasSidebarActive)
      }

      if (!wasFullscreenActive) {
        store.toggleFullscreenActive()
      }
    }
  }, [])

  return (
    <Container isIOS={isIOS}>
      <div>
        <div>
          <Cta>
            <p>
              <Translate text="print_dialog.text" />
            </p>

            <div>
              <button type="button" className="--cancel" onClick={cancelClick}>
                <Translate text="print_dialog.done" />
              </button>

              <button
                type="button"
                className="--proceed"
                ref={printBtnRef}
                onClick={printClick}
              >
                <Translate
                  text={
                    printed ? `print_dialog.print_again` : `print_dialog.print`
                  }
                />
              </button>
            </div>
          </Cta>
        </div>

        <div />
      </div>

      <div />
    </Container>
  )
}

export default observer(PrintZone)

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  animation: ${(props) => props.theme.animations.fadeIn} 0.5s linear;

  @media only print {
    visibility: hidden;
  }

  > div:first-child {
    display: flex;

    > div:first-child {
      width: 100vw;
      height: 100vh;
      max-width: ${rem(1024)};
      max-height: ${rem(720)};
      display: flex;
      justify-content: center;
      align-items: center;
      border: 7px solid ${(props) => rgba(props.theme.colors.brown, 0.5)};

      ${(props) =>
        props.isIOS &&
        css`
          max-width: 100vw;
          max-height: 100vh;
        `}
    }

    > div:last-child {
      flex-grow: 1;
      background-color: ${(props) => rgba(props.theme.colors.brown, 0.5)};
      backdrop-filter: blur(2px);
    }
  }

  > div:last-child {
    flex-grow: 1;
    background-color: ${(props) => rgba(props.theme.colors.brown, 0.5)};
    backdrop-filter: blur(2px);
  }
`

const Cta = styled.div`
  max-width: ${rem(420)};
  padding: ${rem(20)};
  font-size: ${rem(14)};
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => rgba(props.theme.colors.black, 0.7)};
  border-radius: ${rem(3)};
  backdrop-filter: blur(2px);

  p {
    margin-bottom: ${rem(15)};
  }

  div {
    display: flex;
    justify-content: center;

    > * + * {
      margin-left: ${rem(10)};
    }
  }

  button {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

    padding: 0.4em 1em;
    pointer-events: all;
    border-radius: ${rem(3)};
    text-transform: uppercase;

    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 0.6;
    }

    &.\\--proceed {
      background-color: ${(props) => props.theme.colors.green};
    }

    &.\\--cancel {
      color: ${(props) => props.theme.colors.grey};
      background-color: ${(props) => props.theme.colors.black};
    }
  }
`
