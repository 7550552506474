import React from "react"
import { Layer } from "react-mapbox-gl"

import colors from "../theme/colors"

const layerConfig = {
  sourceId: `geojson-points`,
  filter: [
    `all`,
    [`match`, [`geometry-type`], [`Point`], true, false],
    [`!`, [`has`, `point_count`]],
  ],
  type: `symbol`,
}

const MapPoints = () => {
  return (
    <>
      <Layer
        {...layerConfig}
        id="points"
        layout={{
          "icon-allow-overlap": true,
          "icon-image": `{map_icon_name}`,
          "icon-anchor": `bottom`,
          "icon-size": 0.55,
          "symbol-z-order": `source`,
        }}
        paint={{
          "icon-opacity": [
            `case`,
            [`boolean`, [`feature-state`, `hover`], false],
            0,
            [`boolean`, [`feature-state`, `active`], false],
            0,
            1,
          ],
        }}
      />

      <Layer
        {...layerConfig}
        layout={{
          "icon-allow-overlap": true,
          "icon-image": `{map_icon_active_name}`,
          "icon-anchor": `bottom`,
          "icon-size": 0.55,
        }}
        paint={{
          "icon-opacity": [
            `case`,
            [`boolean`, [`feature-state`, `hover`], false],
            1,
            [`boolean`, [`feature-state`, `active`], false],
            1,
            0,
          ],
        }}
      />

      <Layer
        {...layerConfig}
        id="points-cluster"
        type="circle"
        filter={[
          `all`,
          [`match`, [`geometry-type`], [`Point`], true, false],
          [`has`, `point_count`],
        ]}
        paint={{
          "circle-color": [
            `step`,
            [`get`, `point_count`],
            colors.yellow,
            100,
            colors.orange,
            500,
            colors.red,
          ],
          "circle-radius": [
            `step`,
            [`get`, `point_count`],
            20,
            100,
            30,
            500,
            40,
          ],
          "circle-stroke-width": 3,
          "circle-stroke-opacity": 0.6,
          "circle-stroke-color": [
            `case`,
            [`==`, [`feature-state`, `hover`], true],
            `#000`,
            `#fff`,
          ],
        }}
      />

      <Layer
        {...layerConfig}
        type="symbol"
        filter={[
          `all`,
          [`match`, [`geometry-type`], [`Point`], true, false],
          [`has`, `point_count`],
        ]}
        layout={{
          "text-field": `{point_count_abbreviated}`,
          "text-font": [`Open Sans Bold`],
          "text-size": 12,
        }}
      />
    </>
  )
}

export default MapPoints
