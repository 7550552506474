import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { rem, rgba } from "polished"
import { orderBy } from "lodash"

import SidebarHeader from "./SidebarHeader"
import MenuSite from "./MenuSite"
import Translate from "./Translate"
import { ReactComponent as SvgLink } from "../assets/images/icons/link.svg"
import getEmbedParams from "../utils/getEmbedParams"
import typeColors from "../config/typeColors"
import { useStore } from "../store"

const Menu = () => {
  const store = useStore()
  const { guide, language } = getEmbedParams()

  const getGroups = () => {
    const items = []

    store.geojson.features.forEach((feature) => {
      if (feature.geometry.type !== `Point`) return

      const typeKey = feature.properties.type_key
      const typeLabel = feature.properties.type_label

      if (store.categoriesActive.length || store.tagsActive.length) {
        let skip = true

        if (store.categoriesActive.length) {
          if (store.categoriesActive.includes(typeKey)) skip = false
          else {
            const inCats = feature.properties.categories.find((c) =>
              store.categoriesActive.includes(c.slug)
            )
            if (inCats) skip = false
          }
        }

        if (skip && store.tagsActive.length) {
          const inTags = feature.properties.tags.find((t) =>
            store.tagsActive.find((ta) => ta == t.id)
          )
          if (inTags) skip = false
        }

        if (skip) return
      }

      const groupIndex = items.findIndex((i) => i.key === typeKey)

      if (groupIndex < 0) {
        items.push({
          key: typeKey,
          title: typeLabel,
          type: feature.properties.type,
          icon: feature.properties.icon_name,
          color:
            feature.properties.main_category.color ||
            typeColors[feature.properties.type],
          points: [feature],
        })
      } else {
        items[groupIndex].points.push(feature)
      }
    })

    return orderBy(items, [`title`], [`asc`])
  }

  const groups = useMemo(() => getGroups(), [store.geojson])

  return (
    <Container>
      <SidebarHeader title={<Translate text="menu.heading" />} />

      {groups.map((group) => (
        <Group key={group.key}>
          <Heading>
            <img
              src={`/images/icons-points/outline/${group.icon}.svg`}
              alt=""
            />

            {group.title}

            {guide && (
              <a
                href={`https://naturkartan.se/${language}/${guide}/search/${group.key}`}
                target="_parent"
                rel="noopener"
                className="-link"
              >
                <Translate text="menu.all_sites" />

                <SvgLink aria-hidden="true" />
              </a>
            )}
          </Heading>

          {group.points.map((point) => (
            <MenuSite key={point.id} data={point} />
          ))}
        </Group>
      ))}
    </Container>
  )
}

export default observer(Menu)

const Container = styled.div`
  padding: 0.875em 0.75em;
`

const Group = styled.section`
  margin-top: 1em;
  border-radius: ${rem(3)};
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 ${rem(1)} ${rem(1)} 0
    ${(props) => rgba(props.theme.colors.black, 0.1)};
`

const Heading = styled.h3`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

  padding: 0.75em 1em;
  display: flex;
  align-items: center;
  font-size: 0.75em;
  text-transform: uppercase;
  opacity: 0.8;

  img {
    width: 1.8em;
    height: 1.8em;
    margin-right: 0.6em;
    display: inline-block;
    vertical-align: middle;
  }

  a {
    margin-left: auto;
    padding: 0.3em 0;
    padding-left: 1em;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.greyDarkest};

    &:hover {
      color: ${(props) => props.theme.colors.black};
    }

    svg {
      width: 1em;
      height: 1em;
      margin-left: 0.5em;
    }
  }
`
