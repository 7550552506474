import React from "react"
import { observer } from "mobx-react-lite"
import styled, { css } from "styled-components"
import { rem, rgba } from "polished"

import Translate from "./Translate"
import { ReactComponent as SvgFilter } from "../assets/images/icons/filter.svg"
import { useStore } from "../store"

const FiltersToggle = ({ active, buttonRef }) => {
  const store = useStore()

  const buttonClick = (e) => {
    e.preventDefault()
    store.toggleSidebarActive(
      store.sidebarActive !== `filters` ? `filters` : false
    )
    store.setKeyboardAction({ name: `filtersToggled` })
  }

  return (
    <Container active={active}>
      <Translate text="controls.filter">
        {(translation) => (
          <button
            type="button"
            title={translation}
            onClick={buttonClick}
            data-num={
              store.categoriesActive.length + store.tagsActive.length ||
              undefined
            }
            ref={buttonRef}
          >
            <SvgFilter aria-label={translation} />
          </button>
        )}
      </Translate>
    </Container>
  )
}

export default observer(FiltersToggle)

const Container = styled.div`
  button {
    width: ${rem(32)};
    height: ${rem(32)};
    padding: ${rem(8)};
    display: block;
    position: relative;
    border-radius: ${rem(6)};
    color: ${(props) => props.theme.colors.greyDarkest};
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0 ${rem(1)} ${rem(3)} ${rem(1)}
      ${(props) => rgba(props.theme.colors.black, 0.3)};

    &:hover {
      color: ${(props) => props.theme.colors.black};
    }

    &::before {
      /* expanded click area */
      content: "";
      position: absolute;
      top: ${rem(-5)};
      bottom: ${rem(-10)};
      left: ${rem(-10)};
      right: ${rem(-10)};
    }

    &[data-num]::after {
      ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

      content: attr(data-num);
      width: 1.3em;
      height: 1.3em;
      position: absolute;
      bottom: 100%;
      left: 100%;
      transform: translate(-50%, 50%);
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      font-size: 0.8125em;
      color: ${(props) => props.theme.colors.white};
      background-color: ${(props) => props.theme.colors.red};
      border-radius: 50%;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }

  ${(props) =>
    props.active &&
    css`
      button {
        color: ${(props) => props.theme.colors.white};
        background-color: ${(props) => props.theme.colors.black};

        &:hover {
          color: ${(props) => props.theme.colors.grey};
        }
      }
    `}
`
