import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import styled, { css, keyframes } from "styled-components"
import { rem, rgba } from "polished"
import turfBbox from "@turf/bbox"
import { point as turfPoint } from "@turf/helpers"

import Translate from "./Translate"
import { ReactComponent as SvgLocation } from "../assets/images/icons/location.svg"
import { useStore } from "../store"

const LocationToggle = () => {
  const store = useStore()
  const [loading, setLoading] = useState(false)
  const active = !!store.location

  const buttonClick = (e) => {
    e.preventDefault()

    if (store.location) {
      store.setLocation(false)
    } else {
      setLoading(true)

      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLoading(false)
          store.setLocation(position)

          const locationFeat = turfPoint([
            position.coords.longitude,
            position.coords.latitude,
          ])
          store.geojson.features.push(locationFeat)
          store.map.fitBounds(turfBbox(store.geojson), { padding: 60 })
          store.geojson.features.pop()
        },
        (err) => {
          setLoading(false)
          alert(`Couldn’t detect your location: ${err.message} (${err.code})`)
        }
      )
    }
  }

  return (
    <Container active={active} isLoading={loading}>
      <Translate
        text={active ? `controls.location_hide` : `controls.location_show`}
      >
        {(translation) => (
          <button type="button" title={translation} onClick={buttonClick}>
            <SvgLocation aria-label={translation} />
          </button>
        )}
      </Translate>
    </Container>
  )
}

export default observer(LocationToggle)

const loadingAnim = keyframes`
  0% { opacity: 0.6; }
  50% { opacity: 0.3; }
  100% { opacity: 0.6; }
`

const Container = styled.div`
  button {
    width: ${rem(32)};
    height: ${rem(32)};
    padding: ${rem(7)};
    display: block;
    position: relative;
    border-radius: ${rem(6)};
    color: ${(props) => props.theme.colors.greyDarkest};
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0 ${rem(1)} ${rem(3)} ${rem(1)}
      ${(props) => rgba(props.theme.colors.black, 0.3)};

    &:hover {
      color: ${(props) => props.theme.colors.black};
    }

    &::before {
      /* expanded click area */
      content: "";
      position: absolute;
      top: ${rem(-5)};
      bottom: ${rem(-5)};
      left: ${rem(-10)};
      right: ${rem(-10)};
    }
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }

  ${(props) =>
    props.active &&
    css`
      button {
        color: ${(props) => props.theme.colors.white};
        background-color: ${(props) => props.theme.colors.black};

        &:hover {
          color: ${(props) => props.theme.colors.grey};
        }
      }
    `}

  ${(props) =>
    props.isLoading &&
    css`
      animation: ${loadingAnim} 0.8s linear infinite;
    `}
`
