import { observer } from "mobx-react-lite"
import humanizeString from "humanize-string"
import { get } from "lodash"

import locales from "../locales"
import { useStore } from "../store"

const Translate = ({ children, text }) => {
  const store = useStore()

  const isSingle = !Array.isArray(text)
  const keys = isSingle ? [text] : text

  let translations = keys.map((key) => {
    const str = get(locales[store.language], key)
    // eslint-disable-next-line no-console
    if (!str) console.warn(`Translation not found for ${key}`)
    return str || humanizeString(key.split(`.`).pop())
  })

  translations = isSingle ? translations[0] : translations

  return children ? children(translations) : translations
}

export default observer(Translate)
