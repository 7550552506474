import heremapsConfig from "./heremaps"

const mapTiles = {
  lantmateriet: {
    tiles: [
      `https://maptiles.naturkartan-cdn.se/tiles/sweden/EPSG3857/{z}/{x}/{y}.png`,
    ],
    tileSize: 256,
    type: `raster`,
    minZoom: 6.5,
    maxZoom: 22,
    // maxZoom: 13,
    bounds: [
      [11.0273686052, 55.3617373725],
      [23.9033785336, 69.1062472602],
    ],
    zoomFallbackTileset: `outdoor`,
  },
  outdoor: {
    tiles: [
      `https://tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=9106f29987fc41eda5246b5463ceeba2`,
    ],
    tileSize: 256,
    type: `raster`,
  },
  hybrid: {
    tiles: [
      `https://2.aerial.maps.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/png8?apiKey=${heremapsConfig.apiKey}`,
    ],
    tileSize: 256,
    type: `raster`,
  },
}

const mapTileNames = Object.keys(mapTiles)

const mapTileNameDefault = mapTileNames[0]

export { mapTiles as default, mapTileNames, mapTileNameDefault }
