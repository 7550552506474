import React from "react"
import ReactDOM from "react-dom"

import App from "./App"
import Store, { StoreProvider } from "./store"

const store = new Store()

const Root = () => (
  <StoreProvider store={store}>
    <App />
  </StoreProvider>
)

ReactDOM.render(<Root />, document.getElementById(`root`))
