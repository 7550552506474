import React, { useEffect, useRef } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { rem } from "polished"

import Translate from "./Translate"
import { ReactComponent as SvgClose } from "../assets/images/icons/close.svg"
import { useStore } from "../store"

const SidebarHeader = ({ title }) => {
  const store = useStore()
  const closeEl = useRef(null)

  useEffect(() => {
    if (
      store.keyboardAction &&
      [`menuToggled`, `filtersToggled`].includes(store.keyboardAction.name) && // eslint-disable-line prettier/prettier
      closeEl.current
    ) {
      closeEl.current.focus()
      store.setKeyboardAction(null)
    }
  }, [])

  const closeClick = (e) => {
    e.preventDefault()
    store.toggleSidebarActive(false)
  }

  return (
    <Container>
      <h2>{title}</h2>

      <Translate text="_.close">
        {(translation) => (
          <Close title={translation} onClick={closeClick} ref={closeEl}>
            <SvgClose aria-label={translation} />
          </Close>
        )}
      </Translate>
    </Container>
  )
}

export default observer(SidebarHeader)

const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

    font-size: 0.75em;
    text-transform: uppercase;
  }
`

const Close = styled.button.attrs({ type: `button` })`
  width: ${rem(25)};
  height: ${rem(25)};
  position: relative;
  border: 2px solid ${(props) => props.theme.colors.greyDark};
  border-radius: 50%;

  &:hover {
    border-color: ${(props) => props.theme.colors.greyDarkest};
  }

  &::before {
    /* expanded click area */
    content: "";
    position: absolute;
    top: ${rem(-5)};
    bottom: ${rem(-5)};
    left: ${rem(-5)};
    right: ${rem(-5)};
  }

  svg {
    width: 100%;
    height: 100%;
    padding: ${rem(6)};
    display: block;
  }
`
