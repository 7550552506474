import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { rem, rgba } from "polished"

import Translate from "./Translate"
import { mapTileNames } from "../config/mapTiles"
import { useStore } from "../store"

const StyleControl = ({ onButtonClick }) => {
  const store = useStore()

  const buttonClick = (e) => {
    e.preventDefault()

    const { style } = e.currentTarget.dataset
    store.setMapStyle(style)

    if (onButtonClick) {
      onButtonClick(e)
    }
  }

  return (
    <Container>
      {mapTileNames.map((style) => (
        <button
          key={style}
          type="button"
          className={style === store.mapStyle ? `is-selected` : undefined}
          onClick={buttonClick}
          data-style={style}
        >
          <Translate text={`map_styles.${style}`} />
        </button>
      ))}
    </Container>
  )
}

export default observer(StyleControl)

const Container = styled.div`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

  white-space: nowrap;
  font-size: ${rem(12)};
  border-radius: ${rem(5)};
  box-shadow: 0 ${rem(1)} ${rem(3)} ${rem(1)}
    ${(props) => rgba(props.theme.colors.black, 0.3)};

  button {
    height: ${rem(25)};
    padding: 0 ${rem(7)};
    line-height: ${rem(20)};
    display: inline-block;
    position: relative;
    color: ${(props) => props.theme.colors.greyDarkest};
    background-color: ${(props) => props.theme.colors.white};

    &::before {
      /* expanded click area */
      content: "";
      position: absolute;
      top: ${rem(-5)};
      bottom: ${rem(-5)};
      left: 0;
      right: 0;
    }

    &:hover,
    &:focus {
      z-index: 1;
      color: ${(props) => props.theme.colors.black};
    }

    &.is-selected {
      color: ${(props) => props.theme.colors.white};
      background-color: ${(props) => props.theme.colors.black};

      &:hover {
        color: ${(props) => props.theme.colors.grey};
      }
    }

    &:first-child {
      border-radius: ${rem(5)} 0 0 ${rem(5)};
    }
    &:last-child {
      border-radius: 0 ${rem(5)} ${rem(5)} 0;
    }

    &:not(:last-child) {
      border-right: 1px solid ${(props) => props.theme.colors.greyDark};
    }
  }
`
