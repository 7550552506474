const parsePostMessage = (e) => {
  let method = null
  let data = null

  if (e && e.data && e.data.owner && e.data.owner == `naturkartan`) {
    method = e.data.method
    data = e.data.data
  }

  return { method, data }
}

export default parsePostMessage
