const getOffset = (el) => {
  const rect = el.getBoundingClientRect()
  return {
    top: rect.top + document.body.scrollTop,
    left: rect.left + document.body.scrollLeft,
  }
}

const centerScrollInParent = (parent, target) => {
  parent.scrollTop =
    parent.scrollTop +
    getOffset(target).top -
    getOffset(parent).top -
    parent.offsetHeight / 2 +
    target.offsetHeight / 2

  parent.scrollLeft =
    parent.scrollLeft +
    getOffset(target).left -
    getOffset(parent).left -
    parent.offsetWidth / 2 +
    target.offsetWidth / 2
}

export default centerScrollInParent
