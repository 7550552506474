import getEmbedParams from "./getEmbedParams"

const postEmbedMessage = (method, data = true) => {
  if (window.parent === window) return

  window.parent.postMessage(
    JSON.parse(
      JSON.stringify({
        owner: `naturkartan`,
        iframeId: getEmbedParams(`iframeId`),
        method,
        data,
      })
    ),
    `*`
  )
}

export default postEmbedMessage
