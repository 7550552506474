import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { Source, Layer } from "react-mapbox-gl"
import turfLength from "@turf/length"

import { useStore } from "../store"

const MapMeasure = () => {
  const store = useStore()

  const measureGeojson = {
    type: `FeatureCollection`,
    features: [],
  }

  const measureLinestring = {
    type: `Feature`,
    geometry: {
      type: `LineString`,
      coordinates: [],
    },
  }

  const mapClick = (e) => {
    const features = store.map.queryRenderedFeatures(e.point, {
      layers: [`measure-points`],
    })

    if (measureGeojson.features.length > 1) measureGeojson.features.pop()

    if (features.length) {
      // if a feature was clicked, remove it from the map
      const id = features[0].properties.id
      measureGeojson.features = measureGeojson.features.filter(
        (point) => point.properties.id !== id
      )
    } else {
      measureGeojson.features.push({
        type: `Feature`,
        geometry: {
          type: `Point`,
          coordinates: [e.lngLat.lng, e.lngLat.lat],
        },
        properties: {
          id: String(new Date().getTime()),
        },
      })
    }

    if (measureGeojson.features.length > 1) {
      measureLinestring.geometry.coordinates = measureGeojson.features.map(
        (point) => point.geometry.coordinates
      )

      measureGeojson.features.push(measureLinestring)

      const length = turfLength(measureLinestring, {
        units: `kilometers`,
      }).toFixed(1)
      store.setMeasureValue(length)
    }

    store.map.getSource(`geojson-measure`).setData(measureGeojson)
  }

  const mapMouseMove = (e) => {
    const features = store.map.queryRenderedFeatures(e.point, {
      layers: [`measure-points`],
    })
    store.map.getCanvas().style.cursor = features.length
      ? `pointer`
      : `crosshair`
  }

  useEffect(() => {
    store.map.on(`click`, mapClick)
    store.map.on(`mousemove`, mapMouseMove)

    return () => {
      store.map.off(`click`, mapClick)
      store.map.off(`mousemove`, mapMouseMove)
      store.map.getCanvas().style.cursor = null
    }
  }, [])

  return (
    <>
      <Source
        id="geojson-measure"
        geoJsonSource={{
          type: `geojson`,
          data: {
            type: `FeatureCollection`,
            features: [],
          },
        }}
      />

      <Layer
        sourceId="geojson-measure"
        id="measure-points"
        type="circle"
        paint={{
          "circle-radius": 6,
          "circle-color": `#000`,
        }}
        filter={[`in`, `$type`, `Point`]}
      />

      <Layer
        sourceId="geojson-measure"
        id="measure-lines"
        type="line"
        layout={{
          "line-cap": `round`,
          "line-join": `round`,
        }}
        paint={{
          "line-color": `#000`,
          "line-width": 3,
        }}
        filter={[`in`, `$type`, `LineString`]}
      />
    </>
  )
}

export default observer(MapMeasure)
