import React from "react"
import { observer } from "mobx-react-lite"
import styled, { css } from "styled-components"
import { rem, rgba } from "polished"

import SidebarHeader from "./SidebarHeader"
import Translate from "./Translate"
import { useStore } from "../store"
import { ReactComponent as SvgPin } from "../assets/images/icons/pin.svg"
import { ReactComponent as SvgWheelchairTested } from "../assets/images/icons/wheelchair-tested.svg"

const a11yCategorySlugs = [
  `wheelchair-tested`,
  `wheelchair-accessible`,
  `stroller-accessible`,
  `near-parking`,
  `near-public-transport`,
  `near-toilet`,
]

const Container = styled.div`
  padding: 0.875em 0.75em;
`

const List = styled.ul`
  margin-bottom: 1em;
  padding-bottom: 0.6em;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};

  &:first-of-type {
    margin-top: 1em;
  }
`

const ListItem = styled.li`
  display: inline-block;
  margin: 0 0.5em 0.5em 0;

  button {
    display: block;
    padding: 0.5em;
    display: block;
    color: ${(props) => props.theme.colors.greyDarker};
    border-radius: ${rem(3)};
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0 ${rem(1)} ${rem(1)} 0
      ${(props) => rgba(props.theme.colors.black, 0.1)};

    &:hover {
      color: ${(props) => props.theme.colors.black};

      img {
        opacity: 1;
      }
    }

    ${(props) =>
      props.active &&
      css`
        color: ${(props) => props.theme.colors.black};
        background-color: ${(props) => props.theme.colors.beige};
      `}
  }

  ${(props) =>
    props.type == `category` &&
    css`
      img,
      svg {
        width: 1.4em;
        height: 1.4em;
        margin-right: 0.5em;
        vertical-align: middle;
        opacity: 0.5;

        ${(props) =>
          props.active &&
          css`
            opacity: 1;
          `}
      }

      svg {
        ${(props) =>
          props.active &&
          css`
            color: ${(props) => props.theme.colors.green};
          `}
      }
    `}

  ${(props) =>
    props.type == `tag` &&
    css`
      svg {
        width: 1em;
        height: 1em;
        margin-top: -0.1em;
        margin-right: 0.5em;
        vertical-align: middle;
        opacity: 0.5;

        ${(props) =>
          props.active &&
          css`
            opacity: 1;
          `}
      }
    `}
`

const ToggleAll = styled.div`
  button {
    display: inline-block;
    padding: 0.25em;
    font-size: 0.75em;
    text-transform: uppercase;
  }
`

const CategoriesList = ({ data, active, onClick }) => {
  if (!data.length) return null

  return (
    <List>
      {data.map((cat) => (
        <ListItem
          key={cat.slug}
          active={active.includes(cat.slug)}
          type="category"
        >
          <button type="button" onClick={onClick} data-slug={cat.slug}>
            {cat.slug == `wheelchair-tested` ? (
              <SvgWheelchairTested aria-hidden="true" />
            ) : (
              <img
                src={
                  active.includes(cat.slug)
                    ? `/images/icons-points/active/${cat.name}.svg`
                    : `/images/icons-points/outline/${cat.name}.svg`
                }
                role="presentation"
                alt=""
              />
            )}

            {cat.slug == `wheelchair-tested` ? (
              <Translate text="filters.wheelchair_tested" />
            ) : (
              cat.label
            )}
          </button>
        </ListItem>
      ))}
    </List>
  )
}

const TagsList = ({ data, active, onClick }) => {
  if (!data.length) return null

  return (
    <List hidden>
      {data.map((tag) => (
        <ListItem
          key={tag.id}
          active={active.find((id) => id == tag.id)}
          type="tag"
        >
          <button
            type="button"
            onClick={onClick}
            data-id={tag.id}
            data-name={tag.name}
          >
            <SvgPin aria-hidden="true" />

            {tag.name}
          </button>
        </ListItem>
      ))}
    </List>
  )
}

const Filters = () => {
  const store = useStore()
  const usualCategories = []
  const a11yCategories = []
  const localityTags = store.tags.filter((t) => t.type == `locality`)

  store.categories.forEach((cat) => {
    if (a11yCategorySlugs.includes(cat.slug)) {
      cat.type = `place`
      a11yCategories.push(cat)
    } else usualCategories.push(cat)
  })

  const categoryClick = (e) => {
    e.preventDefault()
    const { slug } = e.currentTarget.dataset
    let newCategories = [...store.categoriesActive]
    const isSelected = newCategories.includes(slug)

    if (isSelected) newCategories = newCategories.filter((i) => i != slug)
    else newCategories.push(slug)

    store.setCategoriesActive(newCategories)
  }

  const tagClick = (e) => {
    e.preventDefault()
    const { id } = e.currentTarget.dataset
    let newTags = [...store.tagsActive]
    const isSelected = newTags.includes(id)

    if (isSelected) newTags = newTags.filter((i) => i != id)
    else newTags.push(id)

    store.setTagsActive(newTags)
  }

  const toggleAllClick = (e) => {
    e.preventDefault()

    if (store.categories.length / 2 > store.categoriesActive.length) {
      store.setCategoriesActive(store.categories.map((i) => i.slug))
      store.setTagsActive(store.tags.map((i) => i.id))
    } else {
      store.setCategoriesActive([])
      store.setTagsActive([])
    }
  }

  return (
    <Container>
      <SidebarHeader title={<Translate text="filters.heading" />} />

      {store.categories && store.categories.length > 0 && (
        <>
          <CategoriesList
            data={usualCategories}
            active={store.categoriesActive}
            onClick={categoryClick}
          />

          <CategoriesList
            data={a11yCategories}
            active={store.categoriesActive}
            onClick={categoryClick}
          />

          <TagsList
            data={localityTags}
            active={store.tagsActive}
            onClick={tagClick}
          />

          <ToggleAll>
            <button type="button" onClick={toggleAllClick}>
              <Translate text="filters.toggle_all" />
            </button>
          </ToggleAll>
        </>
      )}
    </Container>
  )
}

export default observer(Filters)
