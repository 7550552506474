import React from "react"
import { observer } from "mobx-react-lite"
import styled, { css } from "styled-components"

import Translate from "./Translate"
import { ReactComponent as SvgLink } from "../assets/images/icons/link.svg"
import mq from "../theme/mq"
import getEmbedParams from "../utils/getEmbedParams"
import { getTrailLengthByPoint } from "../utils/getTrailLength"
import markdown from "../utils/markdown"
import { useStore } from "../store"

const MenuSite = ({ data }) => {
  const store = useStore()

  const click = (e) => {
    e.preventDefault()
    if (!store.map) return

    store.setMapPointActive(data.id)

    if (window.matchMedia(mq.mediumDown).matches) {
      store.toggleSidebarActive(false)
    }
  }

  const mouseEnter = () => {
    if (!store.map) return
    store.setMapPointHovered(data.id)
  }

  const mouseLeave = () => {
    if (!store.map) return

    if (store.mapPointHovered === data.id) {
      store.setMapPointHovered(null)
    }
  }

  const linkClick = (e) => {
    e.stopPropagation()
  }

  const trailLength = getTrailLengthByPoint(data, store.geojson)
  let url = data.properties.url
  let urlTarget = `_parent`
  let urlText = ``
  let description = data.properties.tagline || data.properties.short_description

  if (getEmbedParams(`stf`)) {
    url = null
    description = null
  } else if (getEmbedParams(`bergslagsleden`)) {
    url = null
  } else if (getEmbedParams(`stolavsleden`)) {
    url = null
  } else if (getEmbedParams(`roslagen`) || getEmbedParams(`visitvarmland`)) {
    url = data.properties.partner_link
    urlText = data.properties.partner_link_text
  } else if (getEmbedParams(`vanilla`)) {
    url = null
  } else if (getEmbedParams(`satellite`)) {
    url = data.properties.link
    urlTarget = `_parent`

    if (store.customSiteLinks) {
      url = store.customSiteLinks[data.properties.original_id]
    }
  }

  let image = null
  let video = null
  if (data.properties.image_url) {
    if (data.properties.image_url.match(/\.(mp4|mov)$/i)) {
      video = data.properties.image_url
    } else {
      image = data.properties.image_url
    }
  }

  let trailStatus = ``
  if (data.properties.trail_status_reported_at) {
    const date = new Date(data.properties.trail_status_reported_at)
    if (date.getFullYear() == 1970) trailStatus = `red`
    else {
      const diff = (new Date() - date) / (1000 * 3600)
      if (diff <= 24) trailStatus = `green`
      else if (diff <= 48) trailStatus = `yellow`
      else if (diff <= 72) trailStatus = `blue`
      else trailStatus = `orange`
    }
  }

  return (
    <Container
      active={store.mapPointActive === data.id}
      hovered={store.mapPointHovered === data.id}
      onClick={click}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      data-menu-point-id={data.id}
    >
      <Img>
        {image && (
          <img
            src={`${image}?w=120&h=72&fit=crop&auto=compress,enhance`}
            alt={data.properties.name}
          />
        )}

        {video && <video src={video} preload="metadata" />}
      </Img>

      <Info>
        <h4 className="-title">{data.properties.name}</h4>

        {description && (
          <div
            className="-desc"
            dangerouslySetInnerHTML={{ __html: markdown(description) }}
          />
        )}

        {trailStatus && (
          <Translate
            text={[`trail_status.title`, `trail_status.${trailStatus}`]}
          >
            {(t) => (
              <TrailStatus
                color={trailStatus}
                dangerouslySetInnerHTML={{
                  __html: `${t[0]}: ${t[1].replace(
                    `%{timestamp}`,
                    new Intl.DateTimeFormat(store.language, {
                      dateStyle: `short`,
                      timeStyle: `short`,
                    }).format(
                      new Date(data.properties.trail_status_reported_at)
                    )
                  )}`,
                }}
              />
            )}
          </Translate>
        )}

        <footer>
          {trailLength && (
            <Translate text="menu.trail_length">
              {(translation) => (
                <b className="-length" title={translation}>
                  {trailLength} km
                </b>
              )}
            </Translate>
          )}

          {url && (
            <a
              href={url}
              target={urlTarget}
              rel="noopener"
              className="-link"
              onClick={linkClick}
            >
              {urlText || <Translate text="menu.more" />}

              <SvgLink aria-hidden="true" />
            </a>
          )}
        </footer>
      </Info>
    </Container>
  )
}

export default observer(MenuSite)

const Container = styled.article`
  padding: 0.625em 0.875em;
  display: flex;
  align-items: flex-start;
  cursor: zoom-in;
  border: solid transparent;
  border-width: 1px 0;
  border-top-color: ${(props) => props.theme.colors.grey};

  ${(props) =>
    props.hovered &&
    css`
      background-color: ${(props) => props.theme.colors.beige};
    `}

  ${(props) =>
    props.active &&
    css`
      background-color: ${(props) => props.theme.colors.beigeDark};
    `}
`

const Img = styled.figure`
  flex-basis: 30%;
  flex-shrink: 0;
  max-width: 7em;
  min-width: 4em;
  margin-right: 1em;
  background-color: ${(props) => props.theme.colors.grey};

  img,
  video {
    width: 100%;
    display: block;
  }
`

const Info = styled.div`
  flex-grow: 1;

  .-title {
    ${({ theme }) => theme.fonts.set(`secondary`, `normal`)}
  }

  .-desc {
    margin-top: 0.2em;
    font-size: 0.875em;
    line-height: 1.5;
    opacity: 0.8;
  }

  footer {
    margin-top: 0.3em;
    display: flex;
    align-items: flex-start;
  }

  .-length {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

    margin-right: 1em;
    padding: 0.2em 0.4em;
    display: inline-block;
    vertical-align: middle;
    font-size: 0.625rem;
    border: 1px solid;
    border-radius: 0.2em;
  }

  .-link {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

    margin-left: auto;
    padding: 0.2em 0.4em;
    display: inline-block;
    vertical-align: middle;
    font-size: 0.625rem;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.greyDarkest};
    border-radius: 0.2em;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }

    svg {
      width: 1.2em;
      height: 1.2em;
      margin-top: -0.3em;
      margin-left: 0.3em;
    }
  }
`

const TrailStatus = styled.div`
  color: ${(props) =>
    props.theme.colors[props.color == `yellow` ? `black` : `white`]};
  background-color: ${(props) => props.theme.colors[props.color]};
  margin-top: 0.2em;
  padding: 0.4em 0.6em;
  border-radius: 0.2em;
  font-size: 0.75em;
  line-height: 1.25;
`
