import { css } from "styled-components"

const hideScrollbar = () => css`
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export default { hideScrollbar }
