import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { rem, rgba } from "polished"

import Translate from "./Translate"
import { useStore } from "../store"
import { ReactComponent as SvgPencil } from "../assets/images/icons/pencil.svg"
import { ReactComponent as SvgPointer } from "../assets/images/icons/pointer.svg"

const MeasureToggle = () => {
  const store = useStore()

  const buttonClick = (e) => {
    e.preventDefault()
    const { enabled } = e.currentTarget.dataset
    store.setEditEnabled(enabled == `true`)
  }

  return (
    <Container>
      <Translate text={[`edit.exit`, `edit.enter`]}>
        {(t) => (
          <>
            <button
              type="button"
              title={t[1]}
              onClick={buttonClick}
              className={!store.editEnabled ? `is-selected` : undefined}
              data-enabled={false}
            >
              <SvgPointer aria-label={t[0]} />
            </button>

            <button
              type="button"
              title={t[1]}
              onClick={buttonClick}
              className={store.editEnabled ? `is-selected` : undefined}
              data-enabled={true}
            >
              <SvgPencil aria-label={t[1]} />
            </button>
          </>
        )}
      </Translate>
    </Container>
  )
}

export default observer(MeasureToggle)

const Container = styled.div`
  button {
    width: ${rem(32)};
    height: ${rem(32)};
    padding: ${rem(8)};
    display: block;
    position: relative;
    color: ${(props) => props.theme.colors.greyDarkest};
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0 ${rem(1)} ${rem(3)} ${rem(1)}
      ${(props) => rgba(props.theme.colors.black, 0.3)};

    &:hover {
      color: ${(props) => props.theme.colors.black};
    }

    &:first-child {
      border-radius: ${rem(6)} ${rem(6)} 0 0;
    }
    &:last-child {
      border-radius: 0 0 ${rem(6)} ${rem(6)};
    }

    &.is-selected {
      color: ${(props) => props.theme.colors.white};
      background-color: ${(props) => props.theme.colors.black};

      &:hover {
        color: ${(props) => props.theme.colors.grey};
      }
    }

    &::before {
      /* expanded click area */
      content: "";
      position: absolute;
      top: ${rem(-5)};
      bottom: ${rem(-5)};
      left: ${rem(-10)};
      right: ${rem(-10)};
    }
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`
