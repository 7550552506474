import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { reaction } from "mobx"
import { debounce } from "lodash"
import { createGlobalStyle } from "styled-components"

import { useStore } from "../store"

let focusMarker = false

const MapA11y = () => {
  const store = useStore()
  let features = []
  let debouncedQueryFeatures = null

  const clearMarkers = () => {
    features.forEach((feature) => {
      if (feature.marker) {
        store.map.getCanvasContainer().removeChild(feature.marker)
        delete feature.marker
      }
    })
  }

  const cancel = () => {
    debouncedQueryFeatures.cancel()
    clearMarkers()
  }

  const init = () => {
    if (!store.map.isMoving()) {
      debouncedQueryFeatures()
    }
  }

  const queryFeatures = () => {
    debouncedQueryFeatures.cancel()
    clearMarkers()

    features = store.map.queryRenderedFeatures({
      layers: [`points`, `points-cluster`],
    })

    if (features.length) {
      store.map.off(`render`, init)
    }

    features.reverse().map((feature) => {
      const position = store.map.project(feature.geometry.coordinates)
      const isCluster = !!feature.properties.point_count

      feature.marker = document.createElement(`button`)
      feature.marker.setAttribute(`aria-label`, feature.properties.name)
      feature.marker.setAttribute(`title`, feature.properties.name)
      // eslint-disable-next-line prettier/prettier
      feature.marker.setAttribute(`class`, `map-a11y-button ${isCluster ? `--cluster` : `` }`)
      feature.marker.style.display = `block`
      // eslint-disable-next-line prettier/prettier
      feature.marker.style.transform = `translate(-50%, ${isCluster ? `-50%` : `-90%` }) translate(${position.x}px, ${position.y}px)`

      feature.marker.addEventListener(`click`, (e) => {
        e.preventDefault()
        if (isCluster) {
          store.map
            .getSource(`geojson-points`)
            .getClusterExpansionZoom(
              feature.properties.cluster_id,
              (err, zoom) => {
                if (err) return
                focusMarker = true
                store.map.easeTo({
                  center: feature.geometry.coordinates,
                  zoom: zoom,
                })
              }
            )
        } else store.setMapPointActive(feature.id)
      })

      feature.marker.addEventListener(`focus`, () => {
        store.setMapPointActive(null)
        store.setMapPointHovered(feature.id)
      })

      store.map.getCanvasContainer().appendChild(feature.marker)

      return feature
    })

    if (focusMarker) {
      focusMarker = false

      if (features.length) {
        const clusterFeature = features.find((f) => !!f.properties.point_count)
        if (clusterFeature) clusterFeature.marker.focus()
        else features[0].marker.focus()
      }
    }
  }

  useEffect(() => {
    const reactionKeyboardAction = reaction(
      () => store.keyboardAction,
      () => {
        if (!store.keyboardAction) return

        switch (store.keyboardAction.name) {
          case `pointCardClosed`: {
            const feature = features.find(
              (f) => f.id == store.keyboardAction.id
            )
            if (feature && feature.marker) feature.marker.focus()
            break
          }
        }
      }
    )

    debouncedQueryFeatures = debounce(queryFeatures, 500)
    store.map.on(`movestart`, cancel)
    store.map.on(`moveend`, init)
    store.map.on(`render`, init)
    store.map.on(`sourcedata`, init)

    return () => {
      store.map.off(`movestart`, cancel)
      store.map.off(`moveend`, init)
      store.map.off(`render`, init)
      store.map.off(`sourcedata`, init)
      debouncedQueryFeatures.cancel()
      reactionKeyboardAction()
    }
  }, [])

  return <Styles />
}

export default observer(MapA11y)

const Styles = createGlobalStyle`
  .map-a11y-button {
    width: 0;
    height: 0;
    position: fixed;
    z-index: 1;
    font-size: 0;
    line-height: 0;
    /* DEBUGGER: */
    /* width: 35px; */
    /* height: 50px; */
    /* outline: 2px solid black; */

    &&:focus {
      width: 35px;
      height: 50px;
      outline-color: ${(props) => props.theme.colors.red};

      &.--cluster {
        width: 45px;
        height: 45px;
      }
    }
  }
`
