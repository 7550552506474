import marked from "marked"

const renderer = new marked.Renderer()
renderer.link = (href, title, text) =>
  `<a href="${href}" title="${title}" target="_blank">${text}</a>`

const markdown = (str) => {
  str = marked(str, { renderer })
  // remove markdown leftovers of a truncated text
  str = str.replace(/[**]/g, ``)
  str = str.replace(/[_]/g, ``)
  // -
  return str
}

export default markdown
