import React from "react"
import { makeObservable, observable, action } from "mobx"
import { get, orderBy, capitalize } from "lodash"
import browserLang from "browser-lang"

import elevationExcludes from "./config/elevationExcludes"
import { mapTileNames, mapTileNameDefault } from "./config/mapTiles"
import getEmbedParams from "./utils/getEmbedParams"
import postEmbedMessage from "./utils/postEmbedMessage"
import {
  filterGeojsonBy,
  splitGeojson,
  anyTrailSurfaceInGeojson,
} from "./utils/geojson"
import mq from "./theme/mq"
import { languages } from "./locales"

const embedParams = getEmbedParams()

let embedLanguage = embedParams.language
if (!embedLanguage || !languages.includes(embedLanguage)) {
  embedLanguage = browserLang({ languages: languages, fallback: languages[0] })
}

let embedStyle = embedParams.style
if (!embedStyle || !mapTileNames.includes(embedStyle)) {
  embedStyle = mapTileNameDefault
}

let embedSidebarActive = false
if (embedParams.menuActive !== false) {
  if (
    embedParams.menuActive === true ||
    (embedParams.menuActive === `widescreen` &&
      window.matchMedia(mq.xlargeUp).matches)
  ) {
    embedSidebarActive = `menu`
  }
} else {
  if (
    embedParams.filtersActive === true ||
    (embedParams.filtersActive === `widescreen` &&
      window.matchMedia(mq.xlargeUp).matches)
  ) {
    embedSidebarActive = `filters`
  }
}

class Store {
  language = embedLanguage // string
  map = null // object
  mapStyle = embedStyle // string
  zoom = embedParams.zoom || null // int|float
  geojson = null // object
  anyTrailSurfaceInGeojson = false
  customSiteLinks = null // object
  categories = []
  categoriesActive = []
  tags = []
  tagsActive = []
  fullscreenActive = false
  mapPointHovered = null // object
  mapPointActive = null // object
  sidebarActive = embedSidebarActive // false|string
  elevationsDisplay = false // bool
  elevationsFeat = null // object
  elevationsPointActive = false // array
  measureActive = false
  measureValue = 0
  location = null // object
  pointCardData = null // object
  printActive = false
  shareActive = false
  legendActive = false
  editEnabled = false
  editData = null // object
  editPointsActive = []
  keyboardAction = null // object

  constructor() {
    makeObservable(this, {
      // language: observable,
      fullscreenActive: observable,
      geojson: observable,
      // anyTrailSurfaceInGeojson: observable,
      // customSiteLinks: observable,
      // categories: observable,
      categoriesActive: observable,
      // tags: observable,
      tagsActive: observable,
      map: observable,
      mapStyle: observable,
      zoom: observable,
      sidebarActive: observable,
      mapPointHovered: observable,
      mapPointActive: observable,
      elevationsFeat: observable,
      elevationsDisplay: observable,
      elevationsPointActive: observable,
      measureActive: observable,
      measureValue: observable,
      location: observable,
      pointCardData: observable,
      printActive: observable,
      shareActive: observable,
      legendActive: observable,
      editEnabled: observable,
      // editData: observable,
      // editPointsActive: observable,
      keyboardAction: observable,

      // setLanguage: action,
      toggleFullscreenActive: action,
      setMap: action,
      setGeojson: action,
      setCustomSiteLinks: action,
      toggleSidebarActive: action,
      setMapPointHovered: action,
      setMapPointActive: action,
      setElevationsFeat: action,
      setElevationsDisplay: action,
      setElevationsPointActive: action,
      setMeasureActive: action,
      setMeasureValue: action,
      setLocation: action,
      setMapStyle: action,
      toggleZoom: action,
      setZoom: action,
      setCategoriesActive: action,
      setTagsActive: action,
      setPointCardData: action,
      setPrintActive: action,
      setShareActive: action,
      setLegendActive: action,
      setEditEnabled: action,
      setEditData: action,
      setEditPointsActive: action,
      setKeyboardAction: action,
    })
  }

  // setLanguage = (lang) => {
  //   this.language = lang
  // }

  toggleFullscreenActive = () => {
    this.fullscreenActive = !this.fullscreenActive
    postEmbedMessage(`setFullscreen`, this.fullscreenActive)
  }

  setMap = (map) => {
    this.map = map
  }

  setMapStyle = (index) => {
    this.mapStyle = index
  }

  toggleZoom = (method) => {
    this.zoom = this.zoom + 0.5 * (method === `in` ? 1 : -1)
  }

  setZoom = (level) => {
    this.zoom = level
  }

  setGeojson = (geojson) => {
    this.geojson = geojson
    this.anyTrailSurfaceInGeojson = anyTrailSurfaceInGeojson(geojson)

    // categories & tags

    const categories = []
    const tags = []

    geojson.features.forEach((feat) => {
      if (
        feat.geometry.type !== `Point` ||
        !feat.properties.categories ||
        !feat.properties.categories.length
      )
        return

      feat.properties.categories.forEach((category) => {
        if (!categories.find((c) => c.slug == category.slug)) {
          categories.push({
            type: feat.properties.type,
            slug: category.slug,
            name: category.name,
            label: category.label,
          })
        }
      })

      feat.properties.tags.forEach((tag) => {
        if (!tags.find((t) => t.id == tag.id)) {
          tags.push({
            id: tag.id,
            type: tag.type,
            name: capitalize(tag.name),
          })
        }
      })
    })

    this.categories = orderBy(categories, [`label`], [`asc`])
    this.tags = orderBy(tags, [`name`], [`asc`])

    postEmbedMessage(`geojsonSet`)
  }

  setCustomSiteLinks = (data) => {
    this.customSiteLinks = data
  }

  toggleSidebarActive = (mode) => {
    this.sidebarActive = mode

    if (mode) {
      this.setPointCardData(null)
    }
  }

  setMapPointHovered = (point) => {
    if (!this.map || this.mapPointHovered == point) return

    if (this.mapPointHovered) {
      this.map.setFeatureState(
        { id: this.mapPointHovered, source: `geojson-points` },
        { hover: false }
      )

      const feat = this.geojson.features.find(
        (f) => f.id == this.mapPointHovered
      )
      const relatedId = get(feat, `properties.children[0]`)
      if (relatedId) {
        this.map.setFeatureState(
          { id: relatedId, source: `geojson` },
          { hover: false }
        )
      }

      this.map.getCanvas().style.cursor = null
    }

    this.mapPointHovered = point

    let feat = null

    if (point) {
      feat = this.geojson.features.find((f) => f.id == point)
      if (!feat) return

      this.map.setFeatureState(
        { id: point, source: `geojson-points` },
        { hover: true }
      )

      if (!getEmbedParams(`edit`)) {
        const relatedId = get(feat, `properties.children[0]`)
        if (relatedId) {
          this.map.setFeatureState(
            { id: relatedId, source: `geojson` },
            { hover: true }
          )
        }
      }

      this.map.getCanvas().style.cursor = `pointer`
    }

    postEmbedMessage(`setMapPointHovered`, feat)
  }

  setMapPointActive = (point, showCard = true, flyTo = true) => {
    if (!this.map) return

    // nearby selection when on map edit mode
    if (getEmbedParams(`edit`)) {
      const isActive = this.editPointsActive.includes(point)
      if (isActive) {
        this.editPointsActive = this.editPointsActive.filter((p) => p != point)
      } else {
        this.editPointsActive.push(point)
      }

      this.map.setFeatureState(
        { id: point, source: `geojson-points` },
        { active: !isActive }
      )

      const points = []

      this.geojson.features.forEach((f) => {
        if (f.geometry.type != `Point`) return
        points.push({
          id: f.properties.original_id,
          active: this.editPointsActive.includes(f.id),
        })
      })

      postEmbedMessage(`editPointsChanged`, points)
      return
    }

    if (this.mapPointActive) {
      this.map.setFeatureState(
        { id: this.mapPointActive, source: `geojson-points` },
        { active: false }
      )

      const feat = this.geojson.features.find(
        (f) => f.id === this.mapPointActive
      )
      const relatedId = get(feat, `properties.children[0]`)
      if (relatedId) {
        this.map.setFeatureState(
          { id: relatedId, source: `geojson` },
          { active: false }
        )
      }

      this.setElevationsFeat(null)
    }

    this.mapPointActive = point
    let feat = null

    if (point) {
      const isClustered = !this.map.querySourceFeatures(`geojson-points`, {
        filter: [`all`, [`!has`, `point_count`], [`==`, `$id`, point]],
      }).length

      feat = this.geojson.features.find((f) => f.id == point)
      this.map.setFeatureState(
        { id: point, source: `geojson-points` },
        { active: true }
      )

      const relatedId = get(feat, `properties.children[0]`)
      if (relatedId) {
        this.map.setFeatureState(
          { id: relatedId, source: `geojson` },
          { active: true }
        )

        if (!elevationExcludes.includes(feat.properties.type_key)) {
          const related = this.geojson.features.find((f) => f.id == relatedId)
          if ([`LineString`].includes(related.geometry.type)) {
            this.setElevationsFeat(related)
          }
        }
      }

      if (showCard) {
        this.setPointCardData(point)
      }

      if (flyTo) {
        const params = {
          center: [...feat.geometry.coordinates],
          offset: [
            showCard && window.matchMedia(mq.xsmallUp).matches
              ? window.innerWidth / 5
              : 0,
            window.matchMedia(mq.xsmallDown).matches
              ? -window.innerWidth / 4
              : 0,
          ],
        }

        if (isClustered) params.zoom = 14

        this.map.flyTo(params)
      }
    } else {
      this.setPointCardData(null)
      this.setElevationsDisplay(false)
    }

    postEmbedMessage(`setMapPointActive`, feat)
  }

  setElevationsFeat = (feat) => {
    this.elevationsFeat = feat
  }

  setElevationsDisplay = (mode = null) => {
    mode = mode !== null ? mode : !this.elevationsDisplay
    this.elevationsDisplay = mode

    if (mode) {
      this.setMeasureActive(false)
      this.setLegendActive(false)
    }
  }

  setElevationsPointActive = (coordinates) => {
    this.elevationsPointActive = coordinates
  }

  setMeasureActive = (mode = null) => {
    if (!this.map) return
    mode = mode !== null ? mode : !this.measureActive
    this.measureActive = mode
    this.measureValue = 0

    if (!mode) {
      const mapMeasureSource = this.map.getSource(`geojson-measure`)
      if (mapMeasureSource) {
        mapMeasureSource.setData({ type: `FeatureCollection`, features: [] })
      }
    } else {
      this.setPointCardData(null)
      this.setLegendActive(false)
      this.setElevationsDisplay(false)
    }
  }

  setMeasureValue = (value) => {
    this.measureValue = value
  }

  setLocation = (value) => {
    this.location = value
  }

  setCategoriesActive = (
    categories,
    adjustMap = true,
    doPostEmbedMessage = true
  ) => {
    this.categoriesActive = categories

    if (adjustMap && this.map) {
      const [geojson, geojsonPoints] = splitGeojson(
        filterGeojsonBy(this.geojson, {
          categories: this.categoriesActive,
          tags: this.tagsActive,
        })
      )

      this.map.getSource(`geojson`).setData(geojson)
      this.map.getSource(`geojson-points`).setData(geojsonPoints)
    }

    if (doPostEmbedMessage) {
      postEmbedMessage(`setCategoriesActive`, categories)
    }
  }

  setTagsActive = (tags, adjustMap = true, doPostEmbedMessage = true) => {
    this.tagsActive = tags

    if (adjustMap && this.map) {
      const [geojson, geojsonPoints] = splitGeojson(
        filterGeojsonBy(this.geojson, {
          categories: this.categoriesActive,
          tags: this.tagsActive,
        })
      )

      this.map.getSource(`geojson`).setData(geojson)
      this.map.getSource(`geojson-points`).setData(geojsonPoints)
    }

    if (doPostEmbedMessage) {
      postEmbedMessage(`setTagsActive`, tags)
    }
  }

  setPointCardData = (featId) => {
    this.pointCardData = featId
      ? this.geojson.features.find((f) => f.id == featId)
      : null

    if (featId) {
      this.setMeasureActive(false)
      this.setLegendActive(false)
    }
  }

  setPrintActive = (active = true) => {
    if (!this.map) return
    this.printActive = active
  }

  setShareActive = (active = true) => {
    this.shareActive = active
  }

  setLegendActive = (mode = null) => {
    mode = mode !== null ? mode : !this.legendActive
    this.legendActive = mode

    if (mode) {
      this.setPointCardData(null)
      this.setMeasureActive(false)
      this.setElevationsDisplay(false)
    }
  }

  setEditEnabled = (enabled) => {
    this.editEnabled = enabled
  }

  setEditData = (data) => {
    this.editData = data
  }

  setEditPointsActive = (points) => {
    const pointsActive = []

    this.editPointsActive.forEach((p) =>
      this.map.setFeatureState(
        { id: p, source: `geojson-points` },
        { active: false }
      )
    )

    points.forEach((p) => {
      const feat = this.geojson.features.find(
        (f) => f.properties.original_id == p
      )
      if (feat) pointsActive.push(feat.id)
    })

    this.editPointsActive = pointsActive
    this.editPointsActive.forEach((p) =>
      this.map.setFeatureState(
        { id: p, source: `geojson-points` },
        { active: true }
      )
    )
  }

  setKeyboardAction = (data) => {
    this.keyboardAction = data
  }
}

const StoreContext = React.createContext()

const StoreProvider = ({ children, store }) => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

const useStore = () => {
  return React.useContext(StoreContext)
}

// for use with class components
// const withStore = (Component) => (props) => {
//   return <Component {...props} store={useStore()} />
// }

export { Store as default, StoreProvider, useStore }
